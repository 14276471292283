import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('$_accout', ['insuredId']),
    ...mapGetters('$_openingTime', ['gottenInsuranceNotes', 'insuranceNotes']),
  },
  methods: {
    ...mapMutations('$_openingTime', ['SET_GOTTEN_INSURANCE_NOTES', 'SET_INSURANCE_NOTES']),

    // 获取投保须知
    async getInsuranceNotesConfig() {
      if (this.gottenInsuranceNotes) {
        return this.insuranceNotes;
      } else {
        const { code, data } = await this.$apis
          .getInsuranceNotes({
            companyCode: window.sessionStorage.getItem('_companyCode'),
            insuredId: this.insuredId,
          })
          .catch(() => {
            return [];
          });
        this.SET_GOTTEN_INSURANCE_NOTES();
        if (+code === 0) {
          this.SET_INSURANCE_NOTES(data?.pageList || []);
          return data?.pageList || [];
        } else {
          this.SET_INSURANCE_NOTES([]);
          return [];
        }
      }
    },

    async initInsuranceNotes(url, openPeriodType) {
      if (window.sessionStorage.getItem(`_skip_insurance_notes_id_${this.insuredId}`)) {
        window.location.href = url;
        return;
      }
      if (this.insuredId && this.$refs.insuranceNotesRef) {
        const openPeriodTypeMap = {
          ANNUALLY: 19,
          ANNUALLY_MULTI: 20,
          MONTHLY: 21,
          LIFE_EVENTS: 22,
        };
        const response = await this.getInsuranceNotesConfig();
        if (response?.length > 0) {
          const lang = this.$i18n.locale;
          const obj =
            response?.find(
              i => +i?.pageType === +openPeriodTypeMap?.[openPeriodType] && i?.lang === lang,
            )?.insuranceNotice || {};
          if (+obj?.avaliableStatus === 1) {
            this.$refs.insuranceNotesRef.title = obj?.headLine || '';
            this.$refs.insuranceNotesRef.btnText = obj?.readRemindContent || '';
            if (obj?.itemType === 'PAGE_LIST') {
              this.$refs.insuranceNotesRef.contentType = 'PICTURE';
              this.$refs.insuranceNotesRef.content = obj?.picList || [];
            } else {
              this.$refs.insuranceNotesRef.contentType = 'EDITOR';
              this.$refs.insuranceNotesRef.content = obj?.editorContent || '';
              this.$refs.insuranceNotesRef.backgroundColor = obj?.backgroundTone || '#fff';
            }
            this.$refs.insuranceNotesRef.show = true;
            this.$refs.insuranceNotesRef.url = url;
          } else {
            window.location.href = url;
          }
        } else {
          window.location.href = url;
        }
      } else {
        window.location.href = url;
      }
    },
  },
};

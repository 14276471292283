import Apis from '@/api';
import { isNilString } from '@/utils/utils';

export default {
  namespaced: true,
  state: {
    projecData: {
      comId: '', // 企业编码
      projectID: '', //项目编码
    },
    staffNationalityCollection: undefined, // 员工是否需要收集国籍
    relationNationalityCollection: undefined, // 家属是否需要收集国籍
  },
  getters: {
    staffNationalityCollection: state => state.staffNationalityCollection,
    relationNationalityCollection: state => state.relationNationalityCollection,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.projecData = data;
    },
    SET_CONFIG_DATA: (state, data) => {
      state.projecData.comId = data?.comId || '';
      data?.comId && window.sessionStorage.setItem('_companyCode', data?.comId);
      state.projecData.projectID = data?.projNo || '';
      data?.projNo && localStorage.setItem('_projNo', data?.projNo);
    },
    SET_NATIONALITY_CONFIG: (state, value) => {
      state.staffNationalityCollection = value?.staffNationalityCollection;
      state.relationNationalityCollection = value?.relationNationalityCollection;
    },
  },
  actions: {
    getNationalityCollectionConfig({ commit, state }, projNo) {
      if (!projNo) return;
      if (
        !isNilString(state?.staffNationalityCollection) &&
        !isNilString(state?.relationNationalityCollection)
      ) {
        return;
      }
      return Apis.projectDetailByProjNo({ projNo }).then(configRes => {
        if (+configRes?.code === 0) {
          commit('SET_NATIONALITY_CONFIG', {
            relationNationalityCollection: +configRes?.data?.needMemberNat === 1,
            staffNationalityCollection: +configRes?.data?.needStaffNat === 1,
          });
        }
      });
    },
  },
};

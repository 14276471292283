<template>
  <!-- 弹出框组件 -->
  <van-overlay :show="isShow" :style="{ zIndex: zIndex }">
    <div class="custom-dialog">
      <img
        src="@/assets/images/common/close.png"
        alt=""
        class="custom-dialog__close pointer"
        v-if="isClose"
        @click="isShow = false"
      />
      <div v-if="title" class="custom-dialog__title" :style="{ textAlign: titleAlign }">
        {{ title }}
      </div>
      <template v-if="message">
        <div v-if="isHtml" v-html="message" class="custom-dialog__message"></div>
        <p v-else class="custom-dialog__message" :style="{ textAlign: messageAlign }">
          {{ message }}
        </p>
      </template>
      <div class="custom-dialog__button flex-row">
        <button
          v-if="isButtonMultiple"
          class="custom-dialog__button-item custom-dialog__button-item--cancel pointer"
          :style="{ color: themeColor, borderColor: themeColor }"
          @click="actionToClick(false)"
        >
          {{ cancelButtonText }}
        </button>
        <button
          class="custom-dialog__button-item custom-dialog__button-item--confirm pointer"
          :style="{ backgroundColor: themeColor }"
          @click="actionToClick(true)"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  </van-overlay>
</template>

<script>
//多语言
import i18n from '@/lang';
export default {
  name: 'pcCustomDialog',
  props: {},
  data() {
    return {
      isShow: false,
      title: '',
      message: '',
      titleAlign: 'center',
      messageAlign: 'left',
      cancelButtonText: i18n.t('GLOBAL_111'),
      confirmButtonText: i18n.t('GLOBAL_060'),
      isButtonMultiple: false,
      promiseStatus: undefined,
      isHtml: false,
      themeColor: '#f36218',
      isClose: true,
      closeOnPopstate: true,
      zIndex: 1000,
      async: false,
      onConfirm: undefined,
      onCancel: undefined,
    };
  },
  computed: {},
  created() {
    if (this.closeOnPopstate) {
      window.addEventListener(
        'popstate',
        () => {
          this.isShow = false;
        },
        false,
      );
    }
  },
  mounted() {},
  watch: {},
  methods: {
    /* ----------------------行为触发函数------------------------ */
    actionToClick(type) {
      if (this.async) {
        if (type) {
          this.onConfirm(next => {
            this.isShow = !next;
          });
        } else {
          this.onCancel(next => {
            this.isShow = !next;
          });
        }
      } else {
        type ? (this.promiseStatus || {}).resolve() : (this.promiseStatus || {}).reject();
        this.isShow = false;
      }
    },

    /* ----------------------内部处理函数------------------------ */
    onClick() {
      const that = this;
      this.isShow = true;
      return new Promise((resolve, reject) => {
        that.promiseStatus = { resolve, reject };
      });
    },

    /* ----------------------请求调用函数------------------------ */
    ajaxToDo() {},
  },
  components: {},
};
</script>

<style lang="less" scoped>
.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .custom-dialog {
    position: relative;
    padding: 32px 24px 24px;
    max-width: 320px;
    min-width: 260px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    &__close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 20px;
      height: 20px;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 22px;
      text-align: center;
      margin-bottom: 15px;
    }
    &__message {
      font-size: 14px;
      font-weight: bold;
      color: #62636c;
      line-height: 20px;
      margin-bottom: 16px;
      max-height: 300px;
      overflow-y: auto;
    }
    &__button {
      align-items: center;
      height: 44px;
      &-item {
        height: 100%;
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;
        padding: 0 8px;
        &--cancel {
          background-color: #fff;
          border-width: 1px;
          border-style: solid;
          margin-right: 6px;
        }
        &--confirm {
          color: #fff;
        }
      }
    }
  }
}
</style>

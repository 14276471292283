import {
  checkIdNo,
  checkOfficerNo,
  checkBirthNo,
  checkForeignerNo,
  checkPassNo,
  checkHMNo,
  checkTWNo,
  requireNo,
} from '@/utils/reg';
import i18n from '@/lang';
// 证件类型
export const certificateOptions = () => [
  {
    value: 'IDCARD',
    reg: checkIdNo,
    transfer: 1,
    text: i18n.t('GLOBAL_068'),
  },
  {
    value: 'PASSCARD',
    reg: checkPassNo,
    transfer: 2,
    text: i18n.t('GLOBAL_069'),
  },
  {
    value: 'MILITARYID',
    reg: checkOfficerNo,
    transfer: 3,
    text: i18n.t('GLOBAL_071'),
  },
  {
    value: 'HOUSEHOLD_REGISTER',
    reg: checkBirthNo,
    transfer: 4,
    text: i18n.t('GLOBAL_072'),
  },
  {
    value: 'BIRTH_CERTIFICATE',
    reg: checkBirthNo,
    transfer: 10,
    text: i18n.t('GLOBAL_070'),
  },
  {
    value: 'TAIWANPERMIT',
    reg: checkTWNo,
    transfer: 13,
    text: i18n.t('GLOBAL_074'),
  },
  {
    value: 'HMTRESIDENT',
    reg: checkHMNo,
    transfer: 12,
    text: i18n.t('GLOBAL_073'),
  },
  {
    value: 'ALIENRESIDENCEPERMIT',
    reg: checkForeignerNo,
    transfer: 11,
    text: i18n.t('GLOBAL_075'),
  },
  {
    text: i18n.t('GLOBAL_076'),
    value: 'HK_MACO_LIVE',
    transfer: 15,
    reg: checkHMNo,
  },
  {
    text: i18n.t('GLOBAL_077'),
    value: 'TWN_LIVE',
    transfer: 16,
    reg: checkTWNo,
  },
  {
    value: 'OTHER',
    reg: requireNo,
    transfer: 9,
    text: i18n.t('GLOBAL_078'),
  },
];

// 商品适用人群
export const forTheCrowdOptions = [
  {
    value: 1,
    transfer: '1',
    text: '本人',
  },
  {
    value: 2,
    transfer: '2',
    text: '配偶',
  },
  {
    value: 4,
    transfer: '4',
    text: '子女',
  },
  {
    value: 3,
    transfer: '3',
    text: '父母',
  },
  {
    value: 5,
    transfer: '9',
    text: '配偶父母',
  },
];

// 被保险人属性
export const insuredRelationshipOptions = () => [
  {
    value: 'SELF', // 本人
    code: '1', // 商品人群枚举
    personCode: '1', //人员关系枚举
    text: i18n.t('GLOBAL_006'),
  },
  {
    value: 'MATE', // 配偶
    code: '2',
    personCode: '3',
    text: i18n.t('GLOBAL_007'),
  },
  {
    value: 'CHILDREN', // 子女
    code: '4',
    personCode: '4',
    text: i18n.t('GLOBAL_008'),
  },
  {
    value: 'PARENT', // 父母
    code: '3',
    personCode: '2',
    text: i18n.t('GLOBAL_009'),
  },
  {
    value: 'MATE_PARENT', // 配偶父母
    code: '5',
    personCode: '9',
    text: i18n.t('GLOBAL_012'),
  },
  {
    value: 'OTHER', // 其他
    code: '', //商品没有其他
    personCode: '5',
    text: i18n.t('GLOBAL_015'),
  },
];

// 性别
export const genderOptions = () => [
  {
    value: 'MALE',
    text: i18n.t('GLOBAL_079'),
    transfer: [1],
    code: 1,
  },
  {
    value: 'FEMALE',
    text: i18n.t('GLOBAL_080'),
    transfer: [2],
    code: 2,
  },
  // {
  //   value: 'UN_KNOWN',
  //   text: '未知',
  //   transfer: [3, 9],
  // },
];

// 福利金明细状态
export const balanceDetailStatusList = [
  {
    value: 10,
    text: '支付',
  },
  {
    value: 20,
    text: '退款',
  },
  {
    value: 30,
    text: '领取',
  },
  {
    value: 40,
    text: '失效',
  },
];

// 福利金类型
export const categoryStatusList = [
  {
    value: 10,
    text: '保险',
  },
  {
    value: 20,
    text: '医疗',
  },
];

// 保单状态
export const policyStatusList = () => [
  {
    value: '00',
    text: i18n.t('GLOBAL_026'),
    url: require('@/assets/images/policyDetail/canceled.png'),
  },
  {
    value: '01',
    text: i18n.t('GLOBAL_022'),
  },
  {
    value: '02',
    text: i18n.t('GLOBAL_023'),
    url: require('@/assets/images/policyDetail/to_be_paid.png'),
  },
  {
    value: '03',
    text: i18n.t('GLOBAL_024'),
    url: require('@/assets/images/policyDetail/to_be_insured.png'),
  },
  {
    value: '04',
    text: i18n.t('GLOBAL_025'),
    url: require('@/assets/images/policyDetail/completed.png'),
  },
  {
    value: '05',
    text: i18n.t('GLOBAL_027'),
    url: require('@/assets/images/policyDetail/for_a_refund.png'),
  },
  {
    value: '06',
    text: i18n.t('GLOBAL_028'),
    url: require('@/assets/images/policyDetail/refunded.png'),
  },
];
// 字节定制订单状态
export const ByteDancePolicyStatusList = () => [
  {
    value: '00',
    text: i18n.t('GLOBAL_026'),
    url: require('@/assets/images/custom/ByteDance/canceled.png'),
  },
  {
    value: '01',
    text: i18n.t('GLOBAL_022'),
  },
  {
    value: '02',
    text: i18n.t('GLOBAL_023'),
    url: require('@/assets/images/custom/ByteDance/to_be_paid.png'),
  },
  {
    value: '03',
    text: i18n.t('GLOBAL_024'),
    url: require('@/assets/images/custom/ByteDance/to_be_insured.png'),
  },
  {
    value: '04',
    text: i18n.t('GLOBAL_025'),
    url: require('@/assets/images/custom/ByteDance/completed.png'),
  },
  {
    value: '05',
    text: i18n.t('GLOBAL_027'),
    url: require('@/assets/images/custom/ByteDance/for_a_refund.png'),
  },
  {
    value: '06',
    text: i18n.t('GLOBAL_028'),
    url: require('@/assets/images/custom/ByteDance/refunded.png'),
  },
];
// 保单状态转换
// ALL('01', '全部'),
// PAY('02', '待支付'),
// INSURE('03', '待承保'),
// DONE('04', '已完成'),
// CANCEL('00', '已取消'),
// REFUND('05', '待退款'),
// REFUNDED('06', '已退款'),
// UNKNOWN('0', '未知');
export const statusList = [
  {
    code: '01',
    value: 'ALL',
    text: '全部',
  },
  {
    code: '02',
    value: 'PAY',
    text: '待支付',
  },
  {
    code: '03',
    value: 'INSURE',
    text: '待承保',
  },
  {
    code: '04',
    value: 'DONE',
    text: '已完成',
  },
  {
    code: '05',
    value: 'REFUND',
    text: '待退款',
  },
  {
    code: '06',
    value: 'REFUNDED',
    text: '已退款',
  },
  {
    code: '00',
    value: 'CANCEL',
    text: '已取消',
  },
  {
    code: '0',
    value: 'UNKNOWN',
    text: '未知',
  },
];

/**
 * 投保文件
 */
export const insuranceDocumentsOptions = {
  healthAdvice: '健康告知',
  productLiability: '保险条款',
  policyDetails: '产品详情',
  claimNotice: '理赔须知',
  healthServicesManual: '健康服务手册',
  insureNotice: '投保须知',
  teamInsureAuthDecl: '团体投保授权声明',
  teamInsuredPlan: '团体保障计划书',
};

/**
 * 支付方式
 */
export const paymentTypeOptions = () => ({
  FLEXIBLE_WELFARE_PAY: i18n.t('GLOBAL_082'),
  FLEXIBLE_SALARY_DEDUCTION: i18n.t('GLOBAL_083'),
  WELFARE_SALARY_COMBINATION: i18n.t('GLOBAL_087'),
  WECHAT: i18n.t('GLOBAL_084'),
  COMBINATION_PAY: i18n.t('GLOBAL_086'),
  ALIPAY: i18n.t('GLOBAL_085'),
  OFFlINE_PAY: i18n.t('GLOBAL_088'),
});

// 与本人关系
export const relationshipEnum = (showSelf = true, basicColor = '#BACF60', showTypes = []) => {
  let arr = [
    {
      name: i18n.t('GLOBAL_006'),
      code: 'SELF',
      value: '1',
      color: basicColor,
      background: 'linear-gradient(360deg, #3EC595 0%, #9FE6CF 100%)',
      type: 1,
    },
    {
      name: i18n.t('GLOBAL_007'),
      code: 'MATE',
      value: '3',
      color: '#73A0FA',
      background: 'linear-gradient(315deg, #F8935F 0%, #F58E54 100%)',
      type: 3,
    },
    {
      name: i18n.t('GLOBAL_009'),
      code: 'PARENT',
      value: '2',
      color: '#EB7E65',
      background: 'linear-gradient(280deg, #7B9EFD 0%, #7375F2 100%)',
      type: 2,
    },
    {
      name: i18n.t('GLOBAL_012'),
      code: 'MATE_PARENT',
      value: '6',
      color: '#EB7E65',
      background: 'linear-gradient(280deg, #7B9EFD 0%, #7375F2 100%)',
      type: 9,
    },
    {
      name: i18n.t('GLOBAL_008'),
      code: 'CHILDREN',
      value: '4',
      color: '#F7C739',
      background: 'linear-gradient(135deg, #F6A5A5 0%, #EF7372 100%)',
      type: 4,
    },
    {
      name: i18n.t('GLOBAL_015'),
      code: 'OTHER',
      value: '5',
      color: '#11A663',
      background: '#11A663',
      type: 5,
    },
  ];
  if (!showSelf) {
    arr.shift();
  }
  if (showTypes?.length > 0) {
    return arr.filter(i => showTypes?.includes(i?.type));
  }
  return arr;
};

// 性别
export const genderEnum = (limitShow = true) => {
  let arr = [
    { name: i18n.t('GLOBAL_079'), code: 1, value: 1 },
    { name: i18n.t('GLOBAL_080'), code: 2, value: 2 },
    { name: i18n.t('GLOBAL_081'), code: 3, value: 3 },
  ];
  if (limitShow) {
    arr.pop();
    return arr;
  }
  return arr;
};

// 证件类型
export const credentialsTypeEnum = () => [
  { name: i18n.t('GLOBAL_068'), code: 'IDCARD', value: 1 },
  { name: i18n.t('GLOBAL_069'), code: 'PASSCARD', value: 2 },
  { name: i18n.t('GLOBAL_071'), code: 'MILITARYID', value: 3 },
  { name: i18n.t('GLOBAL_072'), code: 'HOUSEHOLD_REGISTER', value: 4 },
  { name: i18n.t('GLOBAL_070'), code: 'BIRTH_CERTIFICATE', value: 10 },
  { name: i18n.t('GLOBAL_075'), code: 'ALIENRESIDENCEPERMIT', value: 11 },
  { name: i18n.t('GLOBAL_073'), code: 'HMTRESIDENT', value: 12 },
  { name: i18n.t('GLOBAL_074'), code: 'TAIWANPERMIT', value: 13 },
  { name: i18n.t('GLOBAL_076'), code: 'HK_MACO_LIVE', value: 15 },
  { name: i18n.t('GLOBAL_077'), code: 'TWN_LIVE', value: 16 },
  { name: i18n.t('GLOBAL_078'), code: 'OTHER', value: 9 },
];

// 弹福开窗类型 ANNUALLY：年度开窗；ANNUALLY_MULTI：一年多开；MONTHLY：月度开窗；LIFE_EVENTS：人生事件
export const WELFARE_LIST = [
  {
    value: 'ANNUALLY',
    code: '19',
    text: '年度注册',
  },
  {
    value: 'ANNUALLY_MULTI',
    code: '20',
    text: '一年多次开窗',
  },
  {
    value: 'MONTHLY',
    code: '21',
    text: '月度注册',
  },
  {
    value: 'LIFE_EVENTS',
    code: '22',
    text: '人生事件',
  },
];
// 数据字典 code 200027
export const ordersTagslist = [
  {
    value: 1,
    desc: 'NO_SIGN',
    desc_cn: '无标签',
    img: '',
  },
  {
    value: 2,
    desc: 'REPUDIATION',
    desc_cn: '保司拒保',
    img: require('@/assets/images/welfareOverview/pauls-no.png'),
    imgPc: require('@/assets/images/welfareOverview/pauls-no-pc.png'),
  },
  {
    value: 3,
    desc: 'PART_REPUDIATION',
    desc_cn: '保司部分拒保',
    img: require('@/assets/images/welfareOverview/pauls-part.png'),
    imgPc: require('@/assets/images/welfareOverview/pauls-part-pc.png'),
  },
  {
    value: 4,
    desc: 'ADJUST_COVERAGE',
    desc_cn: '方案调整',
    img: require('@/assets/images/welfareOverview/tuning.png'),
    imgPc: require('@/assets/images/welfareOverview/tuning-pc.png'),
  },
  {
    value: 5,
    desc: 'REFUNDED',
    desc_cn: '已退款',
    img: require('@/assets/images/welfareOverview/refunds.png'),
    imgPc: require('@/assets/images/welfareOverview/refunds-pc.png'),
  },
  {
    value: 6,
    desc: 'PART_REFUNDS',
    desc_cn: '部分退款',
    img: require('@/assets/images/welfareOverview/part-refunds.png'),
    imgPc: require('@/assets/images/welfareOverview/part-refunds-pc.png'),
  },
  {
    value: 7,
    desc: 'SEPARATION',
    desc_cn: '离职退费',
    img: require('@/assets/images/welfareOverview/separation.png'),
    imgPc: require('@/assets/images/welfareOverview/separation-pc.png'),
  },
  {
    value: 8,
    desc: 'SPECIAL',
    desc_cn: '特殊操作',
    img: require('@/assets/images/welfareOverview/special.png'),
    imgPc: require('@/assets/images/welfareOverview/special-pc.png'),
  },
];

// 区号
export const getCountryCodeOptions = () => [
  { name: i18n.t('GLOBAL_089'), value: '86' },
  { name: i18n.t('GLOBAL_090'), value: '852' },
  { name: i18n.t('GLOBAL_091'), value: '853' },
  { name: i18n.t('GLOBAL_092'), value: '886' },
  { name: i18n.t('GLOBAL_093'), value: '1' },
  { name: i18n.t('GLOBAL_094'), value: '1' },
  { name: i18n.t('GLOBAL_095'), value: '44' },
  { name: i18n.t('GLOBAL_096'), value: '60' },
  { name: i18n.t('GLOBAL_097'), value: '61' },
  { name: i18n.t('GLOBAL_098'), value: '81' },
];

// 国籍
export const nationalityEnum = () => {
  if (i18n?.locale === 'en') {
    return [
      { code: 'C156', text: i18n.t('NATIONALITY_156'), index: 1 },
      { code: 'C344', text: i18n.t('NATIONALITY_344'), index: 2 },
      { code: 'C446', text: i18n.t('NATIONALITY_446'), index: 3 },
      { code: 'C158', text: i18n.t('NATIONALITY_158'), index: 4 },
      { code: 'C004', text: i18n.t('NATIONALITY_004'), index: 5 },
      { code: 'C008', text: i18n.t('NATIONALITY_008'), index: 6 },
      { code: 'C012', text: i18n.t('NATIONALITY_012'), index: 7 },
      { code: 'C016', text: i18n.t('NATIONALITY_016'), index: 8 },
      { code: 'C020', text: i18n.t('NATIONALITY_020'), index: 9 },
      { code: 'C024', text: i18n.t('NATIONALITY_024'), index: 10 },
      { code: 'C010', text: i18n.t('NATIONALITY_010'), index: 11 },
      { code: 'C028', text: i18n.t('NATIONALITY_028'), index: 12 },
      { code: 'C032', text: i18n.t('NATIONALITY_032'), index: 13 },
      { code: 'C051', text: i18n.t('NATIONALITY_051'), index: 14 },
      { code: 'C533', text: i18n.t('NATIONALITY_533'), index: 15 },
      { code: 'C036', text: i18n.t('NATIONALITY_036'), index: 16 },
      { code: 'C040', text: i18n.t('NATIONALITY_040'), index: 17 },
      { code: 'C031', text: i18n.t('NATIONALITY_031'), index: 18 },
      { code: 'C044', text: i18n.t('NATIONALITY_044'), index: 19 },
      { code: 'C048', text: i18n.t('NATIONALITY_048'), index: 20 },
      { code: 'C050', text: i18n.t('NATIONALITY_050'), index: 21 },
      { code: 'C052', text: i18n.t('NATIONALITY_052'), index: 22 },
      { code: 'C112', text: i18n.t('NATIONALITY_112'), index: 23 },
      { code: 'C056', text: i18n.t('NATIONALITY_056'), index: 24 },
      { code: 'C084', text: i18n.t('NATIONALITY_084'), index: 25 },
      { code: 'C204', text: i18n.t('NATIONALITY_204'), index: 26 },
      { code: 'C060', text: i18n.t('NATIONALITY_060'), index: 27 },
      { code: 'C064', text: i18n.t('NATIONALITY_064'), index: 28 },
      { code: 'C068', text: i18n.t('NATIONALITY_068'), index: 29 },
      { code: 'C070', text: i18n.t('NATIONALITY_070'), index: 30 },
      { code: 'C072', text: i18n.t('NATIONALITY_072'), index: 31 },
      { code: 'C074', text: i18n.t('NATIONALITY_074'), index: 32 },
      { code: 'C076', text: i18n.t('NATIONALITY_076'), index: 33 },
      { code: 'C096', text: i18n.t('NATIONALITY_096'), index: 34 },
      { code: 'C100', text: i18n.t('NATIONALITY_100'), index: 35 },
      { code: 'C854', text: i18n.t('NATIONALITY_854'), index: 36 },
      { code: 'C108', text: i18n.t('NATIONALITY_108'), index: 37 },
      { code: 'C116', text: i18n.t('NATIONALITY_116'), index: 38 },
      { code: 'C120', text: i18n.t('NATIONALITY_120'), index: 39 },
      { code: 'C124', text: i18n.t('NATIONALITY_124'), index: 40 },
      { code: 'C132', text: i18n.t('NATIONALITY_132'), index: 41 },
      { code: 'C136', text: i18n.t('NATIONALITY_136'), index: 42 },
      { code: 'C140', text: i18n.t('NATIONALITY_140'), index: 43 },
      { code: 'C148', text: i18n.t('NATIONALITY_148'), index: 44 },
      { code: 'C152', text: i18n.t('NATIONALITY_152'), index: 45 },
      { code: 'C170', text: i18n.t('NATIONALITY_170'), index: 46 },
      { code: 'C174', text: i18n.t('NATIONALITY_174'), index: 47 },
      { code: 'C178', text: i18n.t('NATIONALITY_178'), index: 48 },
      { code: 'C180', text: i18n.t('NATIONALITY_180'), index: 49 },
      { code: 'C184', text: i18n.t('NATIONALITY_184'), index: 50 },
      { code: 'C188', text: i18n.t('NATIONALITY_188'), index: 51 },
      { code: 'C384', text: i18n.t('NATIONALITY_384'), index: 52 },
      { code: 'C191', text: i18n.t('NATIONALITY_191'), index: 53 },
      { code: 'C192', text: i18n.t('NATIONALITY_192'), index: 54 },
      { code: 'C196', text: i18n.t('NATIONALITY_196'), index: 55 },
      { code: 'C203', text: i18n.t('NATIONALITY_203'), index: 56 },
      { code: 'C208', text: i18n.t('NATIONALITY_208'), index: 57 },
      { code: 'C262', text: i18n.t('NATIONALITY_262'), index: 58 },
      { code: 'C212', text: i18n.t('NATIONALITY_212'), index: 59 },
      { code: 'C214', text: i18n.t('NATIONALITY_214'), index: 60 },
      { code: 'C218', text: i18n.t('NATIONALITY_218'), index: 61 },
      { code: 'C818', text: i18n.t('NATIONALITY_818'), index: 62 },
      { code: 'C222', text: i18n.t('NATIONALITY_222'), index: 63 },
      { code: 'C226', text: i18n.t('NATIONALITY_226'), index: 64 },
      { code: 'C232', text: i18n.t('NATIONALITY_232'), index: 65 },
      { code: 'C233', text: i18n.t('NATIONALITY_233'), index: 66 },
      { code: 'C748', text: i18n.t('NATIONALITY_748'), index: 67 },
      { code: 'C231', text: i18n.t('NATIONALITY_231'), index: 68 },
      { code: 'C234', text: i18n.t('NATIONALITY_234'), index: 69 },
      { code: 'C242', text: i18n.t('NATIONALITY_242'), index: 70 },
      { code: 'C246', text: i18n.t('NATIONALITY_246'), index: 71 },
      { code: 'C250', text: i18n.t('NATIONALITY_250'), index: 72 },
      { code: 'C266', text: i18n.t('NATIONALITY_266'), index: 73 },
      { code: 'C270', text: i18n.t('NATIONALITY_270'), index: 74 },
      { code: 'C268', text: i18n.t('NATIONALITY_268'), index: 75 },
      { code: 'C276', text: i18n.t('NATIONALITY_276'), index: 76 },
      { code: 'C288', text: i18n.t('NATIONALITY_288'), index: 77 },
      { code: 'C292', text: i18n.t('NATIONALITY_292'), index: 78 },
      { code: 'C300', text: i18n.t('NATIONALITY_300'), index: 79 },
      { code: 'C304', text: i18n.t('NATIONALITY_304'), index: 80 },
      { code: 'C308', text: i18n.t('NATIONALITY_308'), index: 81 },
      { code: 'C320', text: i18n.t('NATIONALITY_320'), index: 82 },
      { code: 'C324', text: i18n.t('NATIONALITY_324'), index: 83 },
      { code: 'C624', text: i18n.t('NATIONALITY_624'), index: 84 },
      { code: 'C328', text: i18n.t('NATIONALITY_328'), index: 85 },
      { code: 'C332', text: i18n.t('NATIONALITY_332'), index: 86 },
      { code: 'C340', text: i18n.t('NATIONALITY_340'), index: 87 },
      { code: 'C348', text: i18n.t('NATIONALITY_348'), index: 88 },
      { code: 'C352', text: i18n.t('NATIONALITY_352'), index: 89 },
      { code: 'C356', text: i18n.t('NATIONALITY_356'), index: 90 },
      { code: 'C360', text: i18n.t('NATIONALITY_360'), index: 91 },
      { code: 'C368', text: i18n.t('NATIONALITY_368'), index: 92 },
      { code: 'C372', text: i18n.t('NATIONALITY_372'), index: 93 },
      { code: 'C376', text: i18n.t('NATIONALITY_376'), index: 94 },
      { code: 'C380', text: i18n.t('NATIONALITY_380'), index: 95 },
      { code: 'C388', text: i18n.t('NATIONALITY_388'), index: 96 },
      { code: 'C392', text: i18n.t('NATIONALITY_392'), index: 97 },
      { code: 'C400', text: i18n.t('NATIONALITY_400'), index: 98 },
      { code: 'C398', text: i18n.t('NATIONALITY_398'), index: 99 },
      { code: 'C404', text: i18n.t('NATIONALITY_404'), index: 100 },
      { code: 'C296', text: i18n.t('NATIONALITY_296'), index: 101 },
      { code: 'C408', text: i18n.t('NATIONALITY_408'), index: 102 },
      { code: 'C410', text: i18n.t('NATIONALITY_410'), index: 103 },
      { code: 'C414', text: i18n.t('NATIONALITY_414'), index: 104 },
      { code: 'C417', text: i18n.t('NATIONALITY_417'), index: 105 },
      { code: 'C418', text: i18n.t('NATIONALITY_418'), index: 106 },
      { code: 'C428', text: i18n.t('NATIONALITY_428'), index: 107 },
      { code: 'C422', text: i18n.t('NATIONALITY_422'), index: 108 },
      { code: 'C426', text: i18n.t('NATIONALITY_426'), index: 109 },
      { code: 'C430', text: i18n.t('NATIONALITY_430'), index: 110 },
      { code: 'C434', text: i18n.t('NATIONALITY_434'), index: 111 },
      { code: 'C364', text: i18n.t('NATIONALITY_364'), index: 112 },
      { code: 'C442', text: i18n.t('NATIONALITY_442'), index: 113 },
      { code: 'C450', text: i18n.t('NATIONALITY_450'), index: 114 },
      { code: 'C454', text: i18n.t('NATIONALITY_454'), index: 115 },
      { code: 'C458', text: i18n.t('NATIONALITY_458'), index: 116 },
      { code: 'C462', text: i18n.t('NATIONALITY_462'), index: 117 },
      { code: 'C466', text: i18n.t('NATIONALITY_466'), index: 118 },
      { code: 'C470', text: i18n.t('NATIONALITY_470'), index: 119 },
      { code: 'C584', text: i18n.t('NATIONALITY_584'), index: 120 },
      { code: 'C478', text: i18n.t('NATIONALITY_478'), index: 121 },
      { code: 'C480', text: i18n.t('NATIONALITY_480'), index: 122 },
      { code: 'C484', text: i18n.t('NATIONALITY_484'), index: 123 },
      { code: 'C583', text: i18n.t('NATIONALITY_583'), index: 124 },
      { code: 'C498', text: i18n.t('NATIONALITY_498'), index: 125 },
      { code: 'C492', text: i18n.t('NATIONALITY_492'), index: 126 },
      { code: 'C496', text: i18n.t('NATIONALITY_496'), index: 127 },
      { code: 'C499', text: i18n.t('NATIONALITY_499'), index: 128 },
      { code: 'C504', text: i18n.t('NATIONALITY_504'), index: 129 },
      { code: 'C508', text: i18n.t('NATIONALITY_508'), index: 130 },
      { code: 'C104', text: i18n.t('NATIONALITY_104'), index: 131 },
      { code: 'C516', text: i18n.t('NATIONALITY_516'), index: 132 },
      { code: 'C520', text: i18n.t('NATIONALITY_520'), index: 133 },
      { code: 'C524', text: i18n.t('NATIONALITY_524'), index: 134 },
      { code: 'C528', text: i18n.t('NATIONALITY_528'), index: 135 },
      { code: 'C554', text: i18n.t('NATIONALITY_554'), index: 136 },
      { code: 'C558', text: i18n.t('NATIONALITY_558'), index: 137 },
      { code: 'C562', text: i18n.t('NATIONALITY_562'), index: 138 },
      { code: 'C566', text: i18n.t('NATIONALITY_566'), index: 139 },
      { code: 'C570', text: i18n.t('NATIONALITY_570'), index: 140 },
      { code: 'C807', text: i18n.t('NATIONALITY_807'), index: 141 },
      { code: 'C578', text: i18n.t('NATIONALITY_578'), index: 142 },
      { code: 'C634', text: i18n.t('NATIONALITY_634'), index: 143 },
      { code: 'C512', text: i18n.t('NATIONALITY_512'), index: 144 },
      { code: 'C586', text: i18n.t('NATIONALITY_586'), index: 145 },
      { code: 'C585', text: i18n.t('NATIONALITY_585'), index: 146 },
      { code: 'C275', text: i18n.t('NATIONALITY_275'), index: 147 },
      { code: 'C591', text: i18n.t('NATIONALITY_591'), index: 148 },
      { code: 'C598', text: i18n.t('NATIONALITY_598'), index: 149 },
      { code: 'C600', text: i18n.t('NATIONALITY_600'), index: 150 },
      { code: 'C604', text: i18n.t('NATIONALITY_604'), index: 151 },
      { code: 'C608', text: i18n.t('NATIONALITY_608'), index: 152 },
      { code: 'C616', text: i18n.t('NATIONALITY_616'), index: 153 },
      { code: 'C620', text: i18n.t('NATIONALITY_620'), index: 154 },
      { code: 'C642', text: i18n.t('NATIONALITY_642'), index: 155 },
      { code: 'C643', text: i18n.t('NATIONALITY_643'), index: 156 },
      { code: 'C646', text: i18n.t('NATIONALITY_646'), index: 157 },
      { code: 'C659', text: i18n.t('NATIONALITY_659'), index: 158 },
      { code: 'C662', text: i18n.t('NATIONALITY_662'), index: 159 },
      { code: 'C670', text: i18n.t('NATIONALITY_670'), index: 160 },
      { code: 'C882', text: i18n.t('NATIONALITY_882'), index: 161 },
      { code: 'C674', text: i18n.t('NATIONALITY_674'), index: 162 },
      { code: 'C678', text: i18n.t('NATIONALITY_678'), index: 163 },
      { code: 'C682', text: i18n.t('NATIONALITY_682'), index: 164 },
      { code: 'C686', text: i18n.t('NATIONALITY_686'), index: 165 },
      { code: 'C688', text: i18n.t('NATIONALITY_688'), index: 166 },
      { code: 'C690', text: i18n.t('NATIONALITY_690'), index: 167 },
      { code: 'C694', text: i18n.t('NATIONALITY_694'), index: 168 },
      { code: 'C702', text: i18n.t('NATIONALITY_702'), index: 169 },
      { code: 'C703', text: i18n.t('NATIONALITY_703'), index: 170 },
      { code: 'C705', text: i18n.t('NATIONALITY_705'), index: 171 },
      { code: 'C090', text: i18n.t('NATIONALITY_090'), index: 172 },
      { code: 'C706', text: i18n.t('NATIONALITY_706'), index: 173 },
      { code: 'C710', text: i18n.t('NATIONALITY_710'), index: 174 },
      { code: 'C728', text: i18n.t('NATIONALITY_728'), index: 175 },
      { code: 'C724', text: i18n.t('NATIONALITY_724'), index: 176 },
      { code: 'C144', text: i18n.t('NATIONALITY_144'), index: 177 },
      { code: 'C729', text: i18n.t('NATIONALITY_729'), index: 178 },
      { code: 'C740', text: i18n.t('NATIONALITY_740'), index: 179 },
      { code: 'C752', text: i18n.t('NATIONALITY_752'), index: 180 },
      { code: 'C756', text: i18n.t('NATIONALITY_756'), index: 181 },
      { code: 'C760', text: i18n.t('NATIONALITY_760'), index: 182 },
      { code: 'C762', text: i18n.t('NATIONALITY_762'), index: 183 },
      { code: 'C834', text: i18n.t('NATIONALITY_834'), index: 184 },
      { code: 'C764', text: i18n.t('NATIONALITY_764'), index: 185 },
      { code: 'C438', text: i18n.t('NATIONALITY_438'), index: 186 },
      { code: 'C626', text: i18n.t('NATIONALITY_626'), index: 187 },
      { code: 'C440', text: i18n.t('NATIONALITY_440'), index: 188 },
      { code: 'C768', text: i18n.t('NATIONALITY_768'), index: 189 },
      { code: 'C776', text: i18n.t('NATIONALITY_776'), index: 190 },
      { code: 'C780', text: i18n.t('NATIONALITY_780'), index: 191 },
      { code: 'C788', text: i18n.t('NATIONALITY_788'), index: 192 },
      { code: 'C792', text: i18n.t('NATIONALITY_792'), index: 193 },
      { code: 'C795', text: i18n.t('NATIONALITY_795'), index: 194 },
      { code: 'C798', text: i18n.t('NATIONALITY_798'), index: 195 },
      { code: 'C800', text: i18n.t('NATIONALITY_800'), index: 196 },
      { code: 'C804', text: i18n.t('NATIONALITY_804'), index: 197 },
      { code: 'C784', text: i18n.t('NATIONALITY_784'), index: 198 },
      { code: 'C826', text: i18n.t('NATIONALITY_826'), index: 199 },
      { code: 'C840', text: i18n.t('NATIONALITY_840'), index: 200 },
      { code: 'C858', text: i18n.t('NATIONALITY_858'), index: 201 },
      { code: 'C860', text: i18n.t('NATIONALITY_860'), index: 202 },
      { code: 'C548', text: i18n.t('NATIONALITY_548'), index: 203 },
      { code: 'C336', text: i18n.t('NATIONALITY_336'), index: 204 },
      { code: 'C862', text: i18n.t('NATIONALITY_862'), index: 205 },
      { code: 'C704', text: i18n.t('NATIONALITY_704'), index: 206 },
      { code: 'C887', text: i18n.t('NATIONALITY_887'), index: 207 },
      { code: 'C894', text: i18n.t('NATIONALITY_894'), index: 208 },
      { code: 'C716', text: i18n.t('NATIONALITY_716'), index: 209 },
    ];
  } else {
    return [
      { code: 'C156', text: i18n.t('NATIONALITY_156'), index: 1 },
      { code: 'C344', text: i18n.t('NATIONALITY_344'), index: 2 },
      { code: 'C446', text: i18n.t('NATIONALITY_446'), index: 3 },
      { code: 'C158', text: i18n.t('NATIONALITY_158'), index: 4 },
      { code: 'C008', text: i18n.t('NATIONALITY_008'), index: 5 },
      { code: 'C012', text: i18n.t('NATIONALITY_012'), index: 6 },
      { code: 'C004', text: i18n.t('NATIONALITY_004'), index: 7 },
      { code: 'C032', text: i18n.t('NATIONALITY_032'), index: 8 },
      { code: 'C784', text: i18n.t('NATIONALITY_784'), index: 9 },
      { code: 'C533', text: i18n.t('NATIONALITY_533'), index: 10 },
      { code: 'C512', text: i18n.t('NATIONALITY_512'), index: 11 },
      { code: 'C031', text: i18n.t('NATIONALITY_031'), index: 12 },
      { code: 'C818', text: i18n.t('NATIONALITY_818'), index: 13 },
      { code: 'C231', text: i18n.t('NATIONALITY_231'), index: 14 },
      { code: 'C372', text: i18n.t('NATIONALITY_372'), index: 15 },
      { code: 'C233', text: i18n.t('NATIONALITY_233'), index: 16 },
      { code: 'C020', text: i18n.t('NATIONALITY_020'), index: 17 },
      { code: 'C024', text: i18n.t('NATIONALITY_024'), index: 18 },
      { code: 'C028', text: i18n.t('NATIONALITY_028'), index: 19 },
      { code: 'C040', text: i18n.t('NATIONALITY_040'), index: 20 },
      { code: 'C036', text: i18n.t('NATIONALITY_036'), index: 21 },
      { code: 'C052', text: i18n.t('NATIONALITY_052'), index: 22 },
      { code: 'C598', text: i18n.t('NATIONALITY_598'), index: 23 },
      { code: 'C044', text: i18n.t('NATIONALITY_044'), index: 24 },
      { code: 'C586', text: i18n.t('NATIONALITY_586'), index: 25 },
      { code: 'C600', text: i18n.t('NATIONALITY_600'), index: 26 },
      { code: 'C275', text: i18n.t('NATIONALITY_275'), index: 27 },
      { code: 'C048', text: i18n.t('NATIONALITY_048'), index: 28 },
      { code: 'C591', text: i18n.t('NATIONALITY_591'), index: 29 },
      { code: 'C076', text: i18n.t('NATIONALITY_076'), index: 30 },
      { code: 'C112', text: i18n.t('NATIONALITY_112'), index: 31 },
      { code: 'C060', text: i18n.t('NATIONALITY_060'), index: 32 },
      { code: 'C100', text: i18n.t('NATIONALITY_100'), index: 33 },
      { code: 'C807', text: i18n.t('NATIONALITY_807'), index: 34 },
      { code: 'C204', text: i18n.t('NATIONALITY_204'), index: 35 },
      { code: 'C056', text: i18n.t('NATIONALITY_056'), index: 36 },
      { code: 'C352', text: i18n.t('NATIONALITY_352'), index: 37 },
      { code: 'C616', text: i18n.t('NATIONALITY_616'), index: 38 },
      { code: 'C070', text: i18n.t('NATIONALITY_070'), index: 39 },
      { code: 'C068', text: i18n.t('NATIONALITY_068'), index: 40 },
      { code: 'C084', text: i18n.t('NATIONALITY_084'), index: 41 },
      { code: 'C072', text: i18n.t('NATIONALITY_072'), index: 42 },
      { code: 'C064', text: i18n.t('NATIONALITY_064'), index: 43 },
      { code: 'C854', text: i18n.t('NATIONALITY_854'), index: 44 },
      { code: 'C108', text: i18n.t('NATIONALITY_108'), index: 45 },
      { code: 'C074', text: i18n.t('NATIONALITY_074'), index: 46 },
      { code: 'C408', text: i18n.t('NATIONALITY_408'), index: 47 },
      { code: 'C226', text: i18n.t('NATIONALITY_226'), index: 48 },
      { code: 'C208', text: i18n.t('NATIONALITY_208'), index: 49 },
      { code: 'C276', text: i18n.t('NATIONALITY_276'), index: 50 },
      { code: 'C626', text: i18n.t('NATIONALITY_626'), index: 51 },
      { code: 'C768', text: i18n.t('NATIONALITY_768'), index: 52 },
      { code: 'C214', text: i18n.t('NATIONALITY_214'), index: 53 },
      { code: 'C212', text: i18n.t('NATIONALITY_212'), index: 54 },
      { code: 'C643', text: i18n.t('NATIONALITY_643'), index: 55 },
      { code: 'C218', text: i18n.t('NATIONALITY_218'), index: 56 },
      { code: 'C232', text: i18n.t('NATIONALITY_232'), index: 57 },
      { code: 'C250', text: i18n.t('NATIONALITY_250'), index: 58 },
      { code: 'C234', text: i18n.t('NATIONALITY_234'), index: 59 },
      { code: 'C336', text: i18n.t('NATIONALITY_336'), index: 60 },
      { code: 'C608', text: i18n.t('NATIONALITY_608'), index: 61 },
      { code: 'C242', text: i18n.t('NATIONALITY_242'), index: 62 },
      { code: 'C246', text: i18n.t('NATIONALITY_246'), index: 63 },
      { code: 'C132', text: i18n.t('NATIONALITY_132'), index: 64 },
      { code: 'C270', text: i18n.t('NATIONALITY_270'), index: 65 },
      { code: 'C178', text: i18n.t('NATIONALITY_178'), index: 66 },
      { code: 'C180', text: i18n.t('NATIONALITY_180'), index: 67 },
      { code: 'C170', text: i18n.t('NATIONALITY_170'), index: 68 },
      { code: 'C188', text: i18n.t('NATIONALITY_188'), index: 69 },
      { code: 'C308', text: i18n.t('NATIONALITY_308'), index: 70 },
      { code: 'C304', text: i18n.t('NATIONALITY_304'), index: 71 },
      { code: 'C268', text: i18n.t('NATIONALITY_268'), index: 72 },
      { code: 'C192', text: i18n.t('NATIONALITY_192'), index: 73 },
      { code: 'C328', text: i18n.t('NATIONALITY_328'), index: 74 },
      { code: 'C398', text: i18n.t('NATIONALITY_398'), index: 75 },
      { code: 'C332', text: i18n.t('NATIONALITY_332'), index: 76 },
      { code: 'C410', text: i18n.t('NATIONALITY_410'), index: 77 },
      { code: 'C528', text: i18n.t('NATIONALITY_528'), index: 78 },
      { code: 'C499', text: i18n.t('NATIONALITY_499'), index: 79 },
      { code: 'C340', text: i18n.t('NATIONALITY_340'), index: 80 },
      { code: 'C296', text: i18n.t('NATIONALITY_296'), index: 81 },
      { code: 'C262', text: i18n.t('NATIONALITY_262'), index: 82 },
      { code: 'C417', text: i18n.t('NATIONALITY_417'), index: 83 },
      { code: 'C324', text: i18n.t('NATIONALITY_324'), index: 84 },
      { code: 'C624', text: i18n.t('NATIONALITY_624'), index: 85 },
      { code: 'C124', text: i18n.t('NATIONALITY_124'), index: 86 },
      { code: 'C288', text: i18n.t('NATIONALITY_288'), index: 87 },
      { code: 'C266', text: i18n.t('NATIONALITY_266'), index: 88 },
      { code: 'C116', text: i18n.t('NATIONALITY_116'), index: 89 },
      { code: 'C203', text: i18n.t('NATIONALITY_203'), index: 90 },
      { code: 'C716', text: i18n.t('NATIONALITY_716'), index: 91 },
      { code: 'C120', text: i18n.t('NATIONALITY_120'), index: 92 },
      { code: 'C634', text: i18n.t('NATIONALITY_634'), index: 93 },
      { code: 'C136', text: i18n.t('NATIONALITY_136'), index: 94 },
      { code: 'C174', text: i18n.t('NATIONALITY_174'), index: 95 },
      { code: 'C384', text: i18n.t('NATIONALITY_384'), index: 96 },
      { code: 'C414', text: i18n.t('NATIONALITY_414'), index: 97 },
      { code: 'C191', text: i18n.t('NATIONALITY_191'), index: 98 },
      { code: 'C404', text: i18n.t('NATIONALITY_404'), index: 99 },
      { code: 'C184', text: i18n.t('NATIONALITY_184'), index: 100 },
      { code: 'C428', text: i18n.t('NATIONALITY_428'), index: 101 },
      { code: 'C426', text: i18n.t('NATIONALITY_426'), index: 102 },
      { code: 'C418', text: i18n.t('NATIONALITY_418'), index: 103 },
      { code: 'C422', text: i18n.t('NATIONALITY_422'), index: 104 },
      { code: 'C440', text: i18n.t('NATIONALITY_440'), index: 105 },
      { code: 'C430', text: i18n.t('NATIONALITY_430'), index: 106 },
      { code: 'C434', text: i18n.t('NATIONALITY_434'), index: 107 },
      { code: 'C438', text: i18n.t('NATIONALITY_438'), index: 108 },
      { code: 'C442', text: i18n.t('NATIONALITY_442'), index: 109 },
      { code: 'C646', text: i18n.t('NATIONALITY_646'), index: 110 },
      { code: 'C642', text: i18n.t('NATIONALITY_642'), index: 111 },
      { code: 'C450', text: i18n.t('NATIONALITY_450'), index: 112 },
      { code: 'C462', text: i18n.t('NATIONALITY_462'), index: 113 },
      { code: 'C470', text: i18n.t('NATIONALITY_470'), index: 114 },
      { code: 'C454', text: i18n.t('NATIONALITY_454'), index: 115 },
      { code: 'C458', text: i18n.t('NATIONALITY_458'), index: 116 },
      { code: 'C466', text: i18n.t('NATIONALITY_466'), index: 117 },
      { code: 'C584', text: i18n.t('NATIONALITY_584'), index: 118 },
      { code: 'C480', text: i18n.t('NATIONALITY_480'), index: 119 },
      { code: 'C478', text: i18n.t('NATIONALITY_478'), index: 120 },
      { code: 'C840', text: i18n.t('NATIONALITY_840'), index: 121 },
      { code: 'C016', text: i18n.t('NATIONALITY_016'), index: 122 },
      { code: 'C496', text: i18n.t('NATIONALITY_496'), index: 123 },
      { code: 'C050', text: i18n.t('NATIONALITY_050'), index: 124 },
      { code: 'C604', text: i18n.t('NATIONALITY_604'), index: 125 },
      { code: 'C583', text: i18n.t('NATIONALITY_583'), index: 126 },
      { code: 'C104', text: i18n.t('NATIONALITY_104'), index: 127 },
      { code: 'C498', text: i18n.t('NATIONALITY_498'), index: 128 },
      { code: 'C504', text: i18n.t('NATIONALITY_504'), index: 129 },
      { code: 'C492', text: i18n.t('NATIONALITY_492'), index: 130 },
      { code: 'C508', text: i18n.t('NATIONALITY_508'), index: 131 },
      { code: 'C484', text: i18n.t('NATIONALITY_484'), index: 132 },
      { code: 'C516', text: i18n.t('NATIONALITY_516'), index: 133 },
      { code: 'C710', text: i18n.t('NATIONALITY_710'), index: 134 },
      { code: 'C010', text: i18n.t('NATIONALITY_010'), index: 135 },
      { code: 'C728', text: i18n.t('NATIONALITY_728'), index: 136 },
      { code: 'C520', text: i18n.t('NATIONALITY_520'), index: 137 },
      { code: 'C558', text: i18n.t('NATIONALITY_558'), index: 138 },
      { code: 'C524', text: i18n.t('NATIONALITY_524'), index: 139 },
      { code: 'C562', text: i18n.t('NATIONALITY_562'), index: 140 },
      { code: 'C566', text: i18n.t('NATIONALITY_566'), index: 141 },
      { code: 'C570', text: i18n.t('NATIONALITY_570'), index: 142 },
      { code: 'C578', text: i18n.t('NATIONALITY_578'), index: 143 },
      { code: 'C585', text: i18n.t('NATIONALITY_585'), index: 144 },
      { code: 'C620', text: i18n.t('NATIONALITY_620'), index: 145 },
      { code: 'C392', text: i18n.t('NATIONALITY_392'), index: 146 },
      { code: 'C752', text: i18n.t('NATIONALITY_752'), index: 147 },
      { code: 'C756', text: i18n.t('NATIONALITY_756'), index: 148 },
      { code: 'C222', text: i18n.t('NATIONALITY_222'), index: 149 },
      { code: 'C882', text: i18n.t('NATIONALITY_882'), index: 150 },
      { code: 'C688', text: i18n.t('NATIONALITY_688'), index: 151 },
      { code: 'C694', text: i18n.t('NATIONALITY_694'), index: 152 },
      { code: 'C686', text: i18n.t('NATIONALITY_686'), index: 153 },
      { code: 'C196', text: i18n.t('NATIONALITY_196'), index: 154 },
      { code: 'C690', text: i18n.t('NATIONALITY_690'), index: 155 },
      { code: 'C682', text: i18n.t('NATIONALITY_682'), index: 156 },
      { code: 'C678', text: i18n.t('NATIONALITY_678'), index: 157 },
      { code: 'C659', text: i18n.t('NATIONALITY_659'), index: 158 },
      { code: 'C662', text: i18n.t('NATIONALITY_662'), index: 159 },
      { code: 'C674', text: i18n.t('NATIONALITY_674'), index: 160 },
      { code: 'C670', text: i18n.t('NATIONALITY_670'), index: 161 },
      { code: 'C144', text: i18n.t('NATIONALITY_144'), index: 162 },
      { code: 'C703', text: i18n.t('NATIONALITY_703'), index: 163 },
      { code: 'C705', text: i18n.t('NATIONALITY_705'), index: 164 },
      { code: 'C748', text: i18n.t('NATIONALITY_748'), index: 165 },
      { code: 'C729', text: i18n.t('NATIONALITY_729'), index: 166 },
      { code: 'C740', text: i18n.t('NATIONALITY_740'), index: 167 },
      { code: 'C090', text: i18n.t('NATIONALITY_090'), index: 168 },
      { code: 'C706', text: i18n.t('NATIONALITY_706'), index: 169 },
      { code: 'C762', text: i18n.t('NATIONALITY_762'), index: 170 },
      { code: 'C764', text: i18n.t('NATIONALITY_764'), index: 171 },
      { code: 'C834', text: i18n.t('NATIONALITY_834'), index: 172 },
      { code: 'C776', text: i18n.t('NATIONALITY_776'), index: 173 },
      { code: 'C780', text: i18n.t('NATIONALITY_780'), index: 174 },
      { code: 'C788', text: i18n.t('NATIONALITY_788'), index: 175 },
      { code: 'C798', text: i18n.t('NATIONALITY_798'), index: 176 },
      { code: 'C792', text: i18n.t('NATIONALITY_792'), index: 177 },
      { code: 'C795', text: i18n.t('NATIONALITY_795'), index: 178 },
      { code: 'C548', text: i18n.t('NATIONALITY_548'), index: 179 },
      { code: 'C320', text: i18n.t('NATIONALITY_320'), index: 180 },
      { code: 'C862', text: i18n.t('NATIONALITY_862'), index: 181 },
      { code: 'C096', text: i18n.t('NATIONALITY_096'), index: 182 },
      { code: 'C800', text: i18n.t('NATIONALITY_800'), index: 183 },
      { code: 'C804', text: i18n.t('NATIONALITY_804'), index: 184 },
      { code: 'C858', text: i18n.t('NATIONALITY_858'), index: 185 },
      { code: 'C860', text: i18n.t('NATIONALITY_860'), index: 186 },
      { code: 'C724', text: i18n.t('NATIONALITY_724'), index: 187 },
      { code: 'C300', text: i18n.t('NATIONALITY_300'), index: 188 },
      { code: 'C702', text: i18n.t('NATIONALITY_702'), index: 189 },
      { code: 'C554', text: i18n.t('NATIONALITY_554'), index: 190 },
      { code: 'C348', text: i18n.t('NATIONALITY_348'), index: 191 },
      { code: 'C760', text: i18n.t('NATIONALITY_760'), index: 192 },
      { code: 'C388', text: i18n.t('NATIONALITY_388'), index: 193 },
      { code: 'C051', text: i18n.t('NATIONALITY_051'), index: 194 },
      { code: 'C887', text: i18n.t('NATIONALITY_887'), index: 195 },
      { code: 'C368', text: i18n.t('NATIONALITY_368'), index: 196 },
      { code: 'C364', text: i18n.t('NATIONALITY_364'), index: 197 },
      { code: 'C376', text: i18n.t('NATIONALITY_376'), index: 198 },
      { code: 'C380', text: i18n.t('NATIONALITY_380'), index: 199 },
      { code: 'C356', text: i18n.t('NATIONALITY_356'), index: 200 },
      { code: 'C360', text: i18n.t('NATIONALITY_360'), index: 201 },
      { code: 'C826', text: i18n.t('NATIONALITY_826'), index: 202 },
      { code: 'C400', text: i18n.t('NATIONALITY_400'), index: 203 },
      { code: 'C704', text: i18n.t('NATIONALITY_704'), index: 204 },
      { code: 'C894', text: i18n.t('NATIONALITY_894'), index: 205 },
      { code: 'C148', text: i18n.t('NATIONALITY_148'), index: 206 },
      { code: 'C292', text: i18n.t('NATIONALITY_292'), index: 207 },
      { code: 'C152', text: i18n.t('NATIONALITY_152'), index: 208 },
      { code: 'C140', text: i18n.t('NATIONALITY_140'), index: 209 },
    ];
  }
};

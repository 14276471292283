// 以下为新版本弹福3.0页面路由配置
import { isMobile } from '@/utils';
/**
 *
 * @param {*} resolve
 * @param {*} module views下文件夹名，为空读取src > components内容
 * @param {*} filename .vue的文件名
 * @returns
 */
const getComponent = (resolve, module, filename = 'index') => {
  const isIphone = isMobile();
  if (!module) {
    return require([`@/components/${isIphone ? 'Mobile' : 'Desktop'}/${filename}.vue`], resolve);
  } else {
    return require([
      `@/views/${module}/${isIphone ? 'Mobile' : 'Desktop'}/${filename}.vue`,
    ], resolve);
  }
};

// sso页面
const ssoPages = [
  {
    path: '/sso',
    name: 'sso',
    component: resolve => require(['@/views/sso/index.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
];

// 用户登录认证相关页面
const accountPages = [
  {
    path: '/agent',
    name: 'agent', // 代登录
    component: resolve => require(['@/views/account/agent.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/middleware',
    name: 'middleware',
    component: resolve => require(['@/views/account/middleware.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => getComponent(resolve, 'account', 'login'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    component: resolve => getComponent(resolve, 'account', 'authenticate'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/supply',
    name: 'supply',
    component: resolve => getComponent(resolve, 'account', 'supply'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/email',
    name: 'email',
    component: resolve => getComponent(resolve, 'account', 'email'),
    meta: {
      title: '邮箱认证',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: resolve => getComponent(resolve, 'account', 'error'),
    meta: {
      title: ' ',
    },
  },
];

// 公众号相关页面
const publicPages = [
  {
    path: '/certification',
    name: 'certification',
    component: resolve => getComponent(resolve, 'account', 'certification'),
    meta: {
      title: '认证',
    },
  },
];

// 健管相关页面
const healthManagementPages = [
  {
    path: '/medicalHome',
    name: 'medicalHome',
    component: resolve => require(['@/views/healthManagement/medicalHome.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/dedicatedService',
    name: 'dedicatedService',
    component: resolve => require(['@/views/healthManagement/dedicatedService.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/residentAddress',
    name: 'residentAddress',
    component: resolve => require(['@/views/healthManagement/residentAddress.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
];

// 健康小屋页面
const healthCabinPages = [
  {
    path: '/healthCabinContainer',
    name: 'healthCabinContainer',
    component: resolve => require(['@/views/healthCabin/container.vue'], resolve),
    meta: {
      title: '授权',
    },
  },
  {
    path: '/healthCabinAuthAq',
    name: 'healthCabinAuthAq',
    component: resolve => require(['@/views/healthCabin/authAq.vue'], resolve),
    meta: {
      title: '授权',
    },
  },
  {
    path: '/healthCabinAuthSp',
    name: 'healthCabinAuthSp',
    component: resolve => require(['@/views/healthCabin/authSp.vue'], resolve),
    meta: {
      title: '员工信息授权',
    },
  },
  {
    path: '/healthCabinHomepage',
    name: 'healthCabinHomepage',
    component: resolve => require(['@/views/healthCabin/homepage.vue'], resolve),
    meta: {
      title: '健康小屋',
    },
  },
  {
    path: '/healthCabinServices',
    name: 'healthCabinServices',
    component: resolve => require(['@/views/healthCabin/services.vue'], resolve),
    meta: {
      title: '选择服务',
    },
  },
  {
    path: '/healthCabinAppointment',
    name: 'healthCabinAppointment',
    component: resolve => require(['@/views/healthCabin/appointment.vue'], resolve),
    meta: {
      title: '健康小屋',
    },
  },
  {
    path: '/healthCabinResult',
    name: 'healthCabinResult',
    component: resolve => require(['@/views/healthCabin/result.vue'], resolve),
    meta: {
      title: '预约结果',
    },
  },
  {
    path: '/healthCabinOrder',
    name: 'healthCabinOrder',
    component: resolve => require(['@/views/healthCabin/order.vue'], resolve),
    meta: {
      title: '预约信息',
    },
  },
];

// 弹福业务相关页面
const businessPages = [
  {
    path: '/homepage',
    name: 'homepage',
    component: resolve => getComponent(resolve, 'homepage'),
    meta: {
      title: ' ', // 首页
    },
  },
  {
    path: '/visitor',
    redirect: '/noLoginHomepage',
  },
  {
    path: '/noLoginHomepage',
    name: 'noLoginHomepage',
    component: resolve => getComponent(resolve, 'homepage', 'noLoginIndex'),
    meta: {
      title: ' ', // 无登录态首页
    },
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: resolve => getComponent(resolve, 'insurance'),
    meta: {
      title: ' ', // 保险落地页
    },
  },
  {
    path: '/balance',
    name: 'balance',
    component: resolve => getComponent(resolve, 'balance'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/choose',
    name: 'choose',
    component: resolve => getComponent(resolve, 'choose'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: resolve => getComponent(resolve, 'choose', 'confirm'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/limitList',
    name: 'limitList',
    component: resolve => getComponent(resolve, 'balance', 'limitList'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: resolve => getComponent(resolve, 'privacy'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/detaioed',
    name: 'detaioed',
    component: resolve => getComponent(resolve, 'balance', 'detaioed'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/insuranceDetail',
    name: 'insuranceDetail',
    component: resolve => getComponent(resolve, 'productDetail'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/paymentResult',
    name: 'paymentResult',
    component: resolve => getComponent(resolve, 'payment', 'result'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: resolve => getComponent(resolve, 'policy'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/policyMaster',
    name: 'policyMaster',
    component: resolve => getComponent(resolve, 'policy', 'master'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/policyDetail',
    name: 'policyDetail',
    component: resolve => getComponent(resolve, 'policyDetail'),
    meta: {
      title: ' ',
    },
  },

  // 健康告知
  {
    path: '/healthNotification',
    name: 'healthNotification',
    component: resolve => getComponent(resolve, 'healthNotification'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/examinationReport/preview',
    name: 'examinationReport',
    component: resolve => require(['@/views/examinationReport/preview.vue'], resolve),
    meta: {
      title: ' ', // PDF
    },
  },
  {
    path: '/settlementConfirm',
    name: 'settlementConfirm',
    component: resolve => getComponent(resolve, 'settlement'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/homeUnopened',
    name: 'homeUnopened',
    component: resolve => getComponent(resolve, 'other', 'unopened'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/forbid',
    name: 'forbid',
    component: resolve => require(['@/views/other/Desktop/forbid.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: resolve => getComponent(resolve, 'userCenter'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/familyMember',
    name: 'familyMember',
    component: resolve => getComponent(resolve, 'familyMember', 'list'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/familyMemberInfo',
    name: 'familyMemberInfo',
    component: resolve => getComponent(resolve, 'familyMember', 'edit'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/welfarePolicy',
    name: 'welfarePolicy',
    component: resolve => getComponent(resolve, 'welfarePolicy'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/unselected',
    name: 'welfareOverviewUnselected',
    component: resolve => getComponent(resolve, 'welfareOverview', 'unselected'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/uncreated',
    name: 'welfareOverviewUncreated',
    component: resolve => getComponent(resolve, 'welfareOverview', 'uncreated'),
    meta: {
      title: ' ',
    },
  },
  // 无项目编码进入此页面
  {
    path: '/detail',
    name: 'welfareOverviewDetail',
    component: resolve => getComponent(resolve, 'welfareOverview', 'detail'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/detailQuicker', // 有项目编码的通用福利总览页面
    name: 'welfareOverviewDetailQuicker',
    component: resolve => getComponent(resolve, 'welfareOverview', 'detailQuicker'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: resolve => getComponent(resolve, 'aboutUs'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/spAgreement',
    name: 'spAgreement',
    component: resolve => getComponent(resolve, null, 'agreement'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/spPrivacy',
    name: 'spPrivacy',
    component: resolve => getComponent(resolve, null, 'privacy'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/spClaim',
    name: 'spClaim',
    component: resolve => require(['@/views/claim/index.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  // 2024-02-23 添加个性化配置
  {
    path: '/cms',
    name: 'cms',
    component: resolve => getComponent(resolve, 'cms'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/cms-invalid',
    name: 'cmsInvalid',
    component: resolve => getComponent(resolve, 'cms', 'invalid'),
    meta: {
      title: ' ',
    },
  },
];

// 个险BBC
const personalInsurance = [
  // 中转页
  {
    path: '/personalInsurance',
    name: 'personalInsurance',
    component: resolve => require(['@/views/personalInsurance/index.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  // 网易
  {
    path: '/neteasePrompt',
    name: 'neteasePrompt',
    component: resolve => require(['@/views/personalInsurance/netease/prompt.vue'], resolve),
    meta: {
      title: '客户告知书',
    },
  },
  {
    path: '/neteaseNotification',
    name: 'neteaseNotification',
    component: resolve => require(['@/views/personalInsurance/netease/notification.vue'], resolve),
    meta: {
      title: '思派（北京）保险经纪有限公司客户告知书',
    },
  },
  // 思派
  {
    path: '/spcarePrompt',
    name: 'spcarePrompt',
    component: resolve => require(['@/views/personalInsurance/spcare/prompt.vue'], resolve),
    meta: {
      title: '客户告知书',
    },
  },
  {
    path: '/spcareNotification',
    name: 'spcareNotification',
    component: resolve => require(['@/views/personalInsurance/spcare/notification.vue'], resolve),
    meta: {
      title: '思派（北京）保险经纪有限公司客户告知书',
    },
  },
];

// 客户定制页面
const customPages = [
  // BIGO定制？（待定）
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: resolve => require(['@/views/custom/Bigo/index.vue'], resolve),
    meta: {
      title: '隐私政策',
    },
  },
  {
    path: '/privacyPolicy/detail',
    name: 'privacyPolicyDetail',
    component: resolve => require(['@/views/custom/Bigo/detail.vue'], resolve),
    meta: {
      title: '隐私政策',
    },
  },
  // 快手定制
  {
    path: '/aqSso',
    name: 'aqSso',
    component: resolve => require(['@/views/custom/AAutoQuicker/sso.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/aqError',
    name: 'aqError',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'error'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/authAq',
    name: 'authAq',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'authAq'),
    meta: {
      title: '授权',
    },
  },
  {
    path: '/authSp',
    name: 'authSp',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'authSp'),
    meta: {
      title: '商保选择平台运营方公告',
    },
  },
  {
    path: '/aqAgreement',
    name: 'aqAgreement',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'agreement'),
    meta: {
      title: '平台服务协议',
    },
  },
  {
    path: '/aqPrivacy',
    name: 'aqPrivacy',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'privacy'),
    meta: {
      title: '隐私声明',
    },
  },
  {
    path: '/familyAuthorization',
    name: 'familyAuthorization',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'familyAuthorization'),
    meta: {
      title: '家属授权页',
    },
  },
  {
    path: '/balanceQuicker',
    name: 'balanceQuicker',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'balanceQuicker'),
    meta: {
      title: '我的积分',
    },
  },
  {
    path: '/aqClaim',
    name: 'aqClaim',
    component: resolve => getComponent(resolve, 'custom/AAutoQuicker', 'claim'),
    meta: {
      title: '商保理赔',
    },
  },
  // 协鑫定制
  {
    path: '/gclSso',
    name: 'gclSso',
    component: resolve => require(['@/views/custom/Gcl/sso.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  // 龙湖静默登录处理页面
  {
    path: '/lfSso',
    name: 'lfSso',
    component: resolve => require(['@/views/custom/Longfor/sso.vue'], resolve),
    meta: {
      title: '授权登录中',
    },
  },
  {
    path: '/lfPayCheck',
    name: 'lfPayCheck',
    component: resolve => require(['@/views/custom/Longfor/payCheck.vue'], resolve),
    meta: {
      title: '支付中···',
    },
  },
  // 追觅定制
  {
    path: '/dreameSso',
    name: 'dreameSso',
    component: resolve => require(['@/views/custom/Dreame/sso.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  // 贝壳 健康管理服务
  {
    path: '/bkHealthManageService',
    name: 'bkHealthManageService',
    component: resolve => require(['@/views/custom/Beike/healthManageService.vue'], resolve),
    meta: {
      title: '健康管理服务',
    },
  },
  // 精电理赔引导页
  {
    path: '/jdClaim',
    name: 'jdClaim',
    component: resolve => require(['@/views/custom/JingDian/claim.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  // 商汤理赔中间页
  {
    path: '/stClaim',
    name: 'stClaim',
    component: resolve => require(['@/views/custom/SenseTime/claim.vue'], resolve),
    meta: {
      title: '理赔',
    },
  },
  // 宁德时代定制告知书页、公告页
  {
    path: '/catlSso',
    name: 'catlSso',
    component: resolve => require(['@/views/custom/Catl/sso.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/catlNotification',
    name: 'catlNotification',
    component: resolve => require(['@/views/custom/Catl/notification.vue'], resolve),
    meta: {
      title: '用户告知书',
    },
  },
  {
    path: '/catlAnnouncement',
    name: 'catlAnnouncement',
    component: resolve => require(['@/views/custom/Catl/announcement.vue'], resolve),
    meta: {
      title: '平台运营方公告',
    },
  },
];

// 高管信息收集表单
const healthSurveyPages = [
  {
    path: '/healthSurvey',
    name: 'healthSurvey',
    component: resolve => require(['@/views/healthSurvey/Desktop/index.vue'], resolve),
    meta: {
      title: ' ',
    },
  },
];

const routes = [
  ...ssoPages,
  ...accountPages,
  ...publicPages,
  ...healthManagementPages,
  ...healthCabinPages,
  ...businessPages,
  ...personalInsurance,
  ...customPages,
  ...healthSurveyPages,
  // 404处理
  {
    path: '*',
    redirect: '/middleware',
  },
];
export default routes;

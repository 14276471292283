// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.ttf?t=1686842213886");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'iconfont'; /* Project id  */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.iconfont {\n  font-family: 'iconfont' !important;\n  font-size: 4.26667vw;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-download:before {\n  content: '\\e601';\n}\n\n.icon-roundcheckfill:before {\n  content: '\\e656';\n}\n\n.icon-radio-button-uncheck:before {\n  content: '\\ec59';\n}\n\n.icon-nav-menu:before {\n  content: '\\e602';\n}\n\n.icon-nav-home:before {\n  content: '\\e603';\n}\n\n.icon-nav-user:before {\n  content: '\\e604';\n}\n", "",{"version":3,"sources":["iconfont.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAE,gBAAgB;EACzC,+DAA2D;AAC7D;;AAEA;EACE,kCAAkC;EAClC,oBAAe;EACf,kBAAkB;EAClB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","file":"iconfont.css","sourcesContent":["@font-face {\n  font-family: 'iconfont'; /* Project id  */\n  src: url('iconfont.ttf?t=1686842213886') format('truetype');\n}\n\n.iconfont {\n  font-family: 'iconfont' !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-download:before {\n  content: '\\e601';\n}\n\n.icon-roundcheckfill:before {\n  content: '\\e656';\n}\n\n.icon-radio-button-uncheck:before {\n  content: '\\ec59';\n}\n\n.icon-nav-menu:before {\n  content: '\\e602';\n}\n\n.icon-nav-home:before {\n  content: '\\e603';\n}\n\n.icon-nav-user:before {\n  content: '\\e604';\n}\n"]}]);
// Exports
module.exports = exports;

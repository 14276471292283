<template>
  <div class="watermark" v-if="isAAutoQuicker">
    <div v-for="(item, index) in 7" :key="`row_${index}`" class="row">
      <div v-for="(item, index) in 2" :key="`column_${index}`" class="column">
        <p>{{ name }} {{ clientId || phoneSuffix || idNumberSunffix }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { isAAutoQuicker } from '@/constants/source';
export default {
  name: 'Watermark',
  props: {},
  data() {
    return {
      name: '',
      idNumber: '',
    };
  },
  computed: {
    isAAutoQuicker() {
      return isAAutoQuicker;
    },
    clientId() {
      const { clientId = '' } = this.$route.query;
      return window.localStorage.getItem('AQ_CUSTOM_FLAG') || clientId || '';
    },
    phoneSuffix() {
      const loginMsg =
        window.localStorage.getItem('loginMsg') &&
        JSON.parse(window.localStorage.getItem('loginMsg'));
      const phone = loginMsg?.phone?.toString();
      return phone?.substring(phone?.length - 4, phone?.length) || '';
    },
    idNumberSunffix() {
      return this.idNumber?.substring(this.idNumber?.length - 4, this.idNumber?.length) || '';
    },
  },
  created() {
    this.$apis.getStateByToken({ hideLoading: true, errorHideToast: true }).then(res => {
      if (res?.code === '0') {
        this.name = res?.data?.personName;
        this.idNumber = res?.data?.credentialsNumber;
      }
    });
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style lang="less" scoped>
.watermark {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.1;
  z-index: 10000;
  text-align: center;
  .row {
    .column {
      display: inline-block;
      width: 50%;
      height: 14vh;
      transform: rotate(-30deg);
      transform-origin: 70% 30%;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dragRef",
      staticClass: "drag",
      style: { left: `${_vm.x}px`, top: `${_vm.y}px` },
      on: {
        mousedown: _vm.touchstart,
        touchstart: _vm.touchstart,
        touchend: _vm.touchend,
        touchmove: _vm.touchmove,
        mouseup: _vm.touchend,
        mousemove: _vm.touchmove,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("img", {
        staticClass: "drag-item",
        style: `width:${_vm.width}px`,
        attrs: { src: _vm.source, alt: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import {
  getQueryString,
  isDingTalk,
  isMobile,
  removeUrlParam,
  getInternetProtocol,
  isWeChat,
} from '@/utils';
import { resolveWeChatSource } from '@/utils/business';
import apis from '@/api';
import store from '@/store';
import { Toast } from 'vant';
import * as dd from 'dingtalk-jsapi';
import { isBaidu, isLongFor } from '@/constants/source';
import authRouterWhiteList from '@/constants/authRouterWhiteList';
import dimissionRouterWhiteList from '@/constants/dimissionRouterWhiteList';
import { pendingMap } from '@/utils/request';
import resolveClaim from '@/utils/claim';
import i18n from '@/lang';

Vue.use(VueRouter);
const router = new VueRouter({
  base: '/spcare-products/health-fw-static',
  routes: [{ path: '/', redirect: { name: 'homepage' } }, ...routes],
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
const getAuthInfo = async () => {
  const res = await apis.checkAuth();
  const { code, data } = res || {};
  if (code === '0') {
    store.commit('$_account/SET_BIZ_STATUS', data?.bizStatus);
    return data;
  }
};
const checkIsLogin = async () => {
  if (!window.localStorage.getItem('loginMsg')) return false;
  const loginMsg = JSON.parse(window.localStorage.getItem('loginMsg'));
  const checkLoginResponse = await apis.checkLoginStatus({
    token: loginMsg?.jwtToken,
    source: window.sessionStorage.getItem('_source'),
  });
  return +checkLoginResponse?.code === 0 && checkLoginResponse?.data;
};
const updateCompanyCode = async () => {
  const access = getQueryString('access', false) || 'FW';
  const source = getQueryString('source', false) || '';
  const companyCode = getQueryString('companyCode', false) || '';
  const configId = getQueryString('configId', false) || '';
  const bizCode = getQueryString('bizCode', false) || 'QB';
  // 以下是给健康小屋留的口子
  const comVisibleClassifyId = getQueryString('comVisibleClassifyId', false) || '';
  const extId = getQueryString('extId', false) || '';
  store.commit('$_account/SET_SSO_USER_INFO', { comVisibleClassifyId, extId });
  // 存储访问入口
  if (!window.sessionStorage.getItem('_access')) {
    // 用来区分从公众号菜单进入的是使用 弹福业务【FW】 还是仅 公众号服务【PUBLIC】
    window.sessionStorage.setItem('_access', access);
  }
  if (!source && !window.sessionStorage.getItem('_source')) {
    window.sessionStorage.setItem('_source', process.env.VUE_APP_DEFAULT_SOURCE);
  } else {
    if (source) {
      window.sessionStorage.setItem('_source', source);
    }
  }
  // 特殊处理字节2022年的链接
  if (
    [234, 94].includes(+configId) &&
    !window.sessionStorage.getItem('_hasRedirectByteDance2022')
  ) {
    window.sessionStorage.setItem('_hasRedirectByteDance2022', 'ok');
    window.location.replace(process.env.VUE_APP_BYTEDANCE_2022_URL);
    return true;
  }
  if (resolveWeChatSource(true, false, false)) {
    window.sessionStorage.setItem('_isJian1bao', '1');
  }
  if (!window.sessionStorage.getItem('_bizCode')) {
    window.sessionStorage.setItem('_bizCode', bizCode);
  }
  // 新链接上有companyCode，直接存
  if (!window.sessionStorage.getItem('_companyCode') && companyCode) {
    window.sessionStorage.setItem('_companyCode', companyCode);
    return;
  }
  // 老链接没有companyCode，但有configId
  if (!window.sessionStorage.getItem('_companyCode') && configId) {
    const res = await apis.getCompanyCodeByConfigId({ configId });
    const { code, data } = res || {};
    code === '0' && window.sessionStorage.setItem('_companyCode', data);
    return;
  }
  if (window.sessionStorage.getItem('_companyCode')) return;
  if (+window.sessionStorage.getItem('_isJian1bao') !== 1) {
    // 兜底处理：如果不是公众号 且 链接没有companyCode、configId，通过认证信息获取企业编码
    const isLogin = await checkIsLogin();
    if (isLogin) {
      const authInfoRes = await getAuthInfo();
      authInfoRes?.companyEncode &&
        window.sessionStorage.setItem('_companyCode', authInfoRes?.companyEncode);
    } else {
      // 正常来说走不到这种情况
    }
  }
};
router.beforeEach(async (to, from, next) => {
  // 获取ip
  getInternetProtocol();
  if (['cms', 'cmsInvalid'].includes(to.name)) {
    next && next();
    return;
  }

  // 无source的通用sso页面、龙湖sso页面直接进入
  if ((to.name === 'sso' && window.location.href.indexOf('source=') < 0) || to.name === 'lfSso') {
    next && next();
    return;
  }

  // 存储企业编码等基本信息，【这个顺序很关键】
  const isBreak = await updateCompanyCode();
  if (isBreak) return;

  // 理赔TPA处理
  if (to.name === 'claim' || window.location.href?.indexOf('/health-fw-static/claim') > -1) {
    await resolveClaim(next);
    return;
  }

  // 有source的sso，或定制sso直接进入
  if (['sso', 'aqSso', 'gclSso', 'dreameSso', 'catlSso', 'visitor'].includes(to?.name)) {
    next && next();
    return;
  }
  // 选择福利页 支付接口弹框取消操作
  if (from.name === 'choose') {
    for (const [key, value] of pendingMap.entries()) {
      value(key);
    }
  }
  // 商汤定制处理
  if (window.location.href.indexOf('/health-fw-static/stSso') > -1) {
    const fwSsoToken = getQueryString('token', false);
    window.location.replace(
      `${window.location.origin}/spcare-products/health-fw-static/sso?fwSsoToken=${fwSsoToken}&source=1501110105`,
    );
    return;
  }
  // 保证老客户链接不受影响
  if (
    window.location.href.indexOf('/health-fw-static/homepage') > -1 &&
    !window.sessionStorage.getItem('_middleware') &&
    !window.sessionStorage.getItem('hasRedirect')
  ) {
    window.sessionStorage.setItem('hasRedirect', 'ok');
    window.location.href = `${window.location.origin}/spcare-products/health-fw-static/middleware${window.location.search}`;
    return;
  }

  // 获取第三方企业配置
  if (Object.keys(store.state?.$_account?.sourceInfo)?.length === 0) {
    // 获取source对应配置信息
    const sourceResponse = await apis.getConfigBySource({
      source: window.sessionStorage.getItem('_source'),
    });
    sourceResponse?.code == 0 &&
      store.commit('$_account/SET_SOURCE_INFO', sourceResponse?.data || {});
  }

  // 判断是否需要清除登录态，非手动登录需要清空登录态
  const isNotOtp = store.state?.$_account?.sourceInfo?.loginMode !== 'OTP';
  if (isNotOtp && !window.sessionStorage.getItem('_hasClearToken') && !isLongFor) {
    window.sessionStorage.setItem('_hasClearToken', 1);
    window.localStorage.removeItem('SP_TOKEN');
    window.localStorage.removeItem('loginMsg');
  }

  // 存储的场景
  // 常规登录场景
  const normalScene =
    window.location.href.indexOf('/health-fw-static/middleware') > -1 || to.name === 'middleware';
  // 常规无登录态访问的场景
  const normalNoLoginScene =
    window.location.href.indexOf('/health-fw-static/noLoginHomepage') > -1 ||
    to.name === 'noLoginHomepage';
  // 代登录场景
  const agentScene = window.location.href.indexOf('/health-fw-static/agent') > -1;
  if (
    !window.sessionStorage.getItem('_middleware') &&
    (normalScene || normalNoLoginScene || agentScene) &&
    window.location.href.indexOf('code=') < 0
  ) {
    // 存储中间页链接，在401或退出登录时会回到此页
    // 条件需要排除掉链接上有code的情况（微信环境），避免退登后授权失败
    let url = window.location.href;
    url = removeUrlParam(url, 'ssoUniqueCode');
    window.sessionStorage.setItem('_middleware', url);
  }
  // 无关业务，可直接进入的配置页面
  if (['homeUnopened', 'forbid', 'privacy'].includes(to.name)) {
    next && next();
    return;
  }
  // 定制：快手sso
  if (
    [
      'aqError',
      'authAq',
      'authSp',
      'healthCabinContainer',
      'healthCabinAuthAq',
      'healthCabinAuthSp',
    ].includes(to?.name)
  ) {
    next && next();
    return;
  }
  if (isDingTalk()) {
    // 设置页面标题
    dd.ready(() => {
      dd.biz.navigation.setTitle({
        title: to.meta.title,
      });
    });
  } else if (isBaidu()) {
    // 兼容如流
    document.title = i18n.t('EXTRA_019');
  } else {
    document.title = to.meta.title;
  }
  try {
    Toast.clear();
    // 获取全局页面基本配置
    store
      .dispatch('$_config/getConfig', window.sessionStorage.getItem('_companyCode'))
      .then(async ({ isOpen = 1, enableDesktop = 0, isOpenPublic = 1, isOpenPrivacy = 0 }) => {
        // 去理赔不经过弹福其他业务判断
        const claimWhiteList = ['spClaim', 'jdClaim'];
        if (to?.query?.target === 'HIE' || claimWhiteList?.includes(to?.name)) {
          next();
          return;
        }
        // 个险BBC访问白名单
        const personalInsuranceWhiteList = [
          'personalInsurance',
          'neteasePrompt',
          'neteaseNotification',
          'spcarePrompt',
          'spcareNotification',
        ];
        if (personalInsuranceWhiteList?.includes(to?.name)) {
          next();
          return;
        }
        // 如果是公众号进入，个人中心始终能访问，不受弹福开关影响
        const isUserCenterInPublic =
          +window.sessionStorage.getItem('_isJian1bao') === 1 &&
          window.sessionStorage.getItem('_access') === 'PUBLIC' &&
          (to.name === 'userCenter' ||
            window.location.href.indexOf('/health-fw-static/userCenter') > -1);
        if (isOpen == 0 && !isUserCenterInPublic) {
          window.location.replace(
            `${window.location.origin}/spcare-products/health-fw-static/homeUnopened`,
          );
          return;
        }
        if (!isMobile() && enableDesktop == 0 && !isUserCenterInPublic) {
          window.location.replace(
            `${window.location.origin}/spcare-products/health-fw-static/forbid`,
          );
          return;
        }
        // 校验通过隐私政策，屏蔽公众号
        if (
          +isOpenPrivacy === 1 &&
          !window.localStorage.getItem('_isPassPrivacy') &&
          !(isWeChat && resolveWeChatSource(true))
        ) {
          next &&
            next({
              name: 'privacy',
              query: {
                ...(to.query || {}),
              },
            });
          return;
        }

        if (
          [
            'agent',
            'middleware',
            'login',
            'authenticate',
            'supply',
            'email',
            'error',
            'promotionByteDance',
            'healthSurvey',
          ].includes(to.name)
        ) {
          next && next();
          return;
        }
        // 公众号福利派是否开启
        if (
          isWeChat &&
          +window.sessionStorage.getItem('_isJian1bao') === 1 &&
          window.sessionStorage.getItem('_access') === 'FW' &&
          +isOpenPublic === 0
        ) {
          window.location.replace(
            `${window.location.origin}/spcare-products/health-fw-static/error?t=22`,
          );
          return;
        }
        // 弹福逻辑[默认未登录或预认证]：页面拦截，进入不在白名单内页面需要完善信息（bizStatus为2）才可访问
        if (
          +window.sessionStorage.getItem('_isJian1bao') !== 1 &&
          !authRouterWhiteList.includes(to.name)
        ) {
          // 首先确认是否更新过认证状态
          const setBizStatus = !/^(?:null|undefined|)$/.test(store.state?.$_account?.bizStatus);
          const authInfoRes = await getAuthInfo();
          const _bizStatus = setBizStatus
            ? store.state.$_account.bizStatus
            : authInfoRes?.bizStatus;
          if (_bizStatus < 2) {
            const search = window.sessionStorage.getItem('_middleware')?.split('?')?.[1];
            window.location.href = `${
              window.location.origin
            }/spcare-products/health-fw-static/supply${
              search ? `?${search}&supplyUrl=${encodeURIComponent(to.fullPath)}` : ''
            }`;
            return;
          }
        }
        // 除公众号下个人中心页面，已离职员工跳转异常页
        if (
          (window.localStorage.getItem('loginMsg') || window.localStorage.getItem('SP_TOKEN')) &&
          !dimissionRouterWhiteList.includes(to?.name) &&
          +window.sessionStorage.getItem('_isOnJob') !== 1
        ) {
          next && next('/error?t=21');
          return;
        }

        // 白名单
        const sceneOne = [
          'homepage',
          'noLoginHomepage',
          'balanceQuicker',
          'welfareOverviewDetailQuicker',
          'aqClaim',
        ].includes(to?.name);
        // 非刷新
        const sceneTwo = !from?.name;
        // 黑名单
        const sceneThree = !['medicalHome', 'dedicatedService', 'residentAddress'].includes(
          to?.name,
        );
        const condition = (sceneOne || sceneTwo) && sceneThree;
        if (condition) {
          await store.dispatch('$_openingTime/getOpeningTime');
          // store.dispatch('$_projecData/getProjecData');
        }
        next && next();
      })
      .catch(() => {});
  } catch (err) {
    console.log(err);
  }
});
router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  } else {
    console.log(error);
  }
});
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(options) {
  const source = router.currentRoute.query?.source || sessionStorage.getItem('_source') || '';
  let newObj = { source };
  let newOptions = undefined;
  if (typeof options === 'object') {
    newOptions = {
      ...options,
      query: {
        ...newObj,
        ...(options.query || {}),
      },
    };
  } else {
    const key = options?.indexOf('/') === -1 ? 'name' : 'path';
    newOptions = {
      [key]: options,
      query: { ...newObj },
    };
  }
  return routerPush.call(this, newOptions);
};
export default router;

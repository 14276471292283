// import apis from '@/api';
// import { getQueryString } from '@/utils';
export default {
  namespaced: true,
  state: {
    projecData: {
      comId: '', // 企业编码
      projectID: '', //项目编码
    },
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.projecData = data;
    },
    SET_CONFIG_DATA: (state, data) => {
      state.projecData.comId = data?.comId || '';
      data?.comId && window.sessionStorage.setItem('_companyCode', data?.comId);
      state.projecData.projectID = data?.projNo || '';
      data?.projNo && localStorage.setItem('_projNo', data?.projNo);
    },
  },
  // ---deleted--2023-0904---历史遗留
  // actions: {
  //   async getProjecData({ commit }) {
  //     try {
  //       const companyCode = getQueryString('companyCode') || sessionStorage.getItem('_companyCode');
  //       if (companyCode) {
  //         const { data, code } = await apis.getYearConfig({ companyCode });
  //         if (code === '0') {
  //           commit('SET_DATA', data);
  //           data.projectID && localStorage.setItem('_projNo', data.projectID);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  // },
};

<template>
  <header class="header">
    <div class="head">
      <div class="logo">
        <!-- <img :src="logoSource" /> -->
      </div>
      <div class="navList" v-if="bottomNavigationShow && (navigationData.picList || []).length > 0">
        <div
          class="nav align-center pointer"
          v-for="(nav, index) in navigationData.picList"
          :key="`nav${index}`"
          @click="skipAction(nav, navigationData.itemKey, 'navs')"
          :style="getColor(nav)"
        >
          <span>{{ nav.configName }}</span>
        </div>
      </div>
      <div v-if="showLanguageSwitch">
        <switch-language />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import mixins from '@/mixins';
import { sourceNoLogin } from '@/constants/source';
import SwitchLanguage from '@/components/Desktop/switchLanguage.vue';
export default {
  name: 'BottomNavBar',
  mixins: [mixins],
  props: {
    findVal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['showNavigationBar', 'themeColor', 'logoSource']),
    ...mapGetters('$_config', ['showLanguageSwitch']),
  },
  data() {
    return {
      defaultColor: '#333333FF',
      navigationData: {},
      bottomNavigationShow: 0,
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getColor(val) {
      console.log(val, this.findVal?.redirectType);
      return this.findVal?.redirectType == val?.redirectType
        ? { color: this.themeColor, background: '#F5F9FCFF' }
        : { color: this.defaultColor };
    },
    async getConfig() {
      const token = window.localStorage.getItem('SP_TOKEN');
      if (token || sourceNoLogin) {
        const hideErrorToast = window.sessionStorage.getItem('_access') === 'HM';
        const companyCode = sessionStorage.getItem('_companyCode') || '';
        const res = await this.$apis.getNavsConfig({ companyCode }, hideErrorToast);
        const { code, data } = res || {};
        if (code === '0') {
          const lang = window.localStorage.getItem('_language');
          this.bottomNavigationShow =
            data?.bottomNavigationBars?.[lang]?.itemList?.[0]?.avaliableStatus === 1;
          this.navigationData = data?.bottomNavigationBars?.[lang]?.itemList?.[0] || {};
        }
      }
    },
  },
  components: { SwitchLanguage },
};
</script>

<style lang="less" scoped>
.header {
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(68, 134, 251, 0.1);
  width: 100%;
  min-width: 1180px;
  .head {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    width: 1180px;
    height: 60px;
    margin: auto;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 32px;
    }
  }
  .navList {
    display: flex;
    .nav {
      padding: 5px 0 3px;
      width: 120px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

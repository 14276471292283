import { stringifyObject, urlcat } from '@/utils/utils';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { isLiby, isAAutoQuicker, source, sourceNoLogin, isLongFor } from '@/constants/source';
import {
  isMobile,
  scene,
  getUrlAllParams,
  objAddToUrlParams,
  getQueryString,
  removeUrlParam,
} from '@/utils';
import { securityDomainList } from '@/constants/AppConstants';
import store from '@/store';
import routes from '@/router/routes';
import loginRouterWhiteList from '@/constants/loginRouterWhiteList';
import { userCenterDefaultData } from '@/constants/publicDefaultConfigData';
import thirdpartyResolve from './thirdParty';
import { goHospitalPage } from '@/utils/routerToHospital';
import insuranceNotes from '@/mixins/insuranceNotes';
import { transferDomain } from '@/utils/business';

// 该渠道只有H5支付
const h5payChannels = ['1500710105'];
export default {
  mixins: [insuranceNotes],
  data() {
    return {
      isWefareOverview: true,
      projNo: localStorage.getItem('_projNo') || '',
    };
  },
  async created() {
    const LifeEventOpen = JSON.parse(sessionStorage.getItem('LifeEventOpen'));
    if (LifeEventOpen && LifeEventOpen?.insuredId) {
      const companyCode = getQueryString('companyCode') || sessionStorage.getItem('_companyCode');
      const { data, code } = await this.$apis.getLifeEventOpenPeriodConfig({
        companyCode,
        insuredId: this.insuredId,
      });
      if (code === '0') {
        if (data?.openPeriodId) {
          data?.insuredId && this.SET_INSURED_ID(data?.insuredId);
          sessionStorage.setItem('LifeEventOpen', JSON.stringify(data));
        }
      }
    }
  },
  computed: {
    ...mapGetters(['themeColor', 'openingTime']),
    ...mapGetters('$_account', ['bizStatus', 'insuredId']),
    // 开窗期判断
    reselectVisible() {
      const openingTime = this.openingTime;
      const chooseType = this.$route?.query?.chooseType;

      //非人生事件
      if (this.openingTime?.length === 1 && chooseType !== 'LIFE_EVENTS') {
        const {
          openPeriodStart,
          openPeriodEnd = '',
          monthlyDayStart = '',
          monthlyDayEnd = '',
          cycleFlag = 0,
          insuredId = null,
        } = openingTime?.[0] || {};
        //循环开窗期判断
        if (+cycleFlag === 1 && monthlyDayStart && monthlyDayEnd) {
          return this.isCirculationOpen({ monthlyDayStart, monthlyDayEnd });
        }
        // 非循环开窗判断
        if (+cycleFlag === 0 && openPeriodStart && openPeriodEnd) {
          const isRun = this.isOpen({ openPeriodStart, openPeriodEnd });
          // insuredId  为空不在开窗名单内
          if (!isRun || !insuredId) {
            const LifeEventOpen = JSON.parse(sessionStorage.getItem('LifeEventOpen'));
            if (LifeEventOpen && LifeEventOpen?.insuredId) {
              if (+LifeEventOpen?.cycleFlag === 1) {
                return this.isCirculationOpen({
                  monthlyDayStart: LifeEventOpen?.monthlyDayStart,
                  monthlyDayEnd: LifeEventOpen?.monthlyDayEnd,
                });
              }
              return this.isOpen({
                openPeriodStart: LifeEventOpen?.openPeriodStart,
                openPeriodEnd: LifeEventOpen?.openPeriodEnd,
              });
            }
          }
          return isRun;
        }
      } else {
        // 人生事件
        const LifeEventOpen = JSON.parse(sessionStorage.getItem('LifeEventOpen'));
        if (LifeEventOpen && LifeEventOpen?.insuredId) {
          const isSelfOpen = this.isOpen({
            openPeriodStart: LifeEventOpen?.openPeriodStart,
            openPeriodEnd: LifeEventOpen?.openPeriodEnd,
          });
          if (isSelfOpen) return true;
          if (+LifeEventOpen?.cycleFlag === 1) {
            return this.isCirculationOpen({
              monthlyDayStart: LifeEventOpen?.monthlyDayStart,
              monthlyDayEnd: LifeEventOpen?.monthlyDayEnd,
            });
          }
          return false;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('$_openingTime', ['SET_DATA']),
    ...mapMutations('$_projecData', ['SET_CONFIG_DATA']),
    ...mapMutations('$_account', ['SET_INSURED_ID']),
    ...mapActions('$_projecData', ['getNationalityCollectionConfig']),

    isOpen({ openPeriodStart, openPeriodEnd }) {
      if (openPeriodStart && openPeriodEnd) {
        const startTime = openPeriodStart.replace(/-/g, '/');
        const endTime = openPeriodEnd.replace(/-/g, '/');
        const Time = new Date().getTime();
        return new Date(endTime).getTime() >= Time && Time >= new Date(startTime).getTime();
      }
    },
    isCirculationOpen({ monthlyDayStart, monthlyDayEnd }) {
      if (monthlyDayStart && monthlyDayEnd) {
        const day = new Date().getDate();
        return monthlyDayEnd >= day && monthlyDayStart <= day;
      }
    },

    // 取消&退款
    async ajaxToCancelPolicy(mainOrderNo) {
      const res = await this.$apis.cancelPolicy(mainOrderNo);
      const { code, message } = res || {};
      if (code === '0') {
        this.$toast(this.$t('GLOBAL_033'));
        setTimeout(() => {
          location.reload();
        }, 1500);
      } else {
        this.$toast.fail({
          message,
          duration: 3000,
        });
      }
    },

    // 下支付订单
    async ajaxToPay({ order, price, discount, callback }) {
      const loadingToast = this.$toast.loading({
        message: this.$t('GLOBAL_000'),
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      });

      const source = scene();
      console.log('mixins>>>source>>>>', source);
      const sceneInfo = {
        // h5支付固定传"h5_info"
        h5_info: {
          type: 'WAP', // 场景类型
          wap_url: window.location.origin, // WAP网站URL地址
          wap_name: '弹性福利', // WAP 网站名
        },
      };

      const params = {
        // insuredName: order?.insurants[0]?.name, // 由后端彩聪确认  无此入参，注释【2023-11-22】
        mainOrderNo: order?.mainOrderNo,
        openid: window.localStorage.getItem('_openid'),
        redirectUrl: this.getRoutersUrl('/paymentResult', {
          mainOrderNo: order?.mainOrderNo,
          source: window.sessionStorage.getItem('_source'),
        }),
        resultUrl: this.getRoutersUrl('/paymentResult', {
          mainOrderNo: order?.mainOrderNo,
          source: window.sessionStorage.getItem('_source'),
        }),
        orderUrl: this.getRoutersUrl('/policy', {
          source: window.sessionStorage.getItem('_source'),
        }),
        source: parseInt(window.sessionStorage.getItem('_source')),
      };

      // 【珑珠定制-202311】增加的入参productNameDisplay 和 longzhuBackUrl
      if (isLongFor) {
        const choosePage = JSON.parse(sessionStorage.getItem('choosePage'));
        let productNameDisplay = '';
        if (choosePage && choosePage?.pageList && choosePage?.pageList[0]) {
          productNameDisplay = choosePage?.pageList[0]?.productNameDisplay || '';
        }
        params.productNameDisplay = productNameDisplay;
        params.longzhuBackUrl = this.getRoutersUrl('/lfPayCheck', {
          source: window.sessionStorage.getItem('_source'), // source
          mainOrderNo: order?.mainOrderNo, // 主订单号
        });
      }

      // console.log('@mixins>>>>params', params);
      const localSource = window.sessionStorage.getItem('_source');
      const transSource = h5payChannels.includes(localSource) || source === 'H5' ? 'H5' : source;
      // console.log('@mixins>>>>params', transSource, localSource, source);
      const res = await this.$apis.submitPayment(
        transSource,
        transSource === 'H5' ? { ...params, sceneInfo } : params,
      );
      const { code, data, message } = res || {};
      loadingToast.clear();
      // console.log('@mixins>>>>code, data, message', code, data, message, price, discount);
      if (code === '0') {
        if (isLiby) {
          const info = JSON.parse(window.localStorage.getItem('loginMsg'));
          await this.$apis.updateHasOrder(info?.companyEncode);
        }
        if (isLongFor) {
          // 【珑珠定制-202311】增加珑珠积分支付判定分支逻辑
          if (data?.urlType === '1' && data?.waitPayAmount === 0) {
            // 因现金支付金额为0，无需到支付页面处理，直接跳转支付结果页
            this.$router.replace({
              name: 'paymentResult',
              query: {
                source: window.sessionStorage.getItem('_source'),
                mainOrderNo: order?.mainOrderNo,
                companyCode: this.$route.query?.companyCode,
                id: order?.mainOrderNo,
              },
            });
            return;
          }
          this.$toast.clear(true);
          const loadingToast = this.$toast.loading({
            message: this.$t('GLOBAL_034'),
            duration: 1500,
            forbidClick: true,
            loadingType: 'spinner',
            className: 'confirm-pay-toast',
          });

          setTimeout(() => {
            sessionStorage.removeItem('confirmCheck');
            if (data?.urlType === '2') {
              // 跳珑珠支付
              loadingToast.clear();
              window.location.href = data?.url;
            } else {
              // 跳正常支付
              const loginMsg =
                window.localStorage.getItem('loginMsg') &&
                JSON.parse(window.localStorage.getItem('loginMsg'));
              const payUrl = data?.url;
              const companyCode = loginMsg?.companyEncode || '';
              const token = loginMsg?.jwtToken || window.localSource.getItem('SP_TOKEN');
              const buzz = encodeURIComponent(JSON.stringify({ companyCode, token }));
              const url = `${payUrl}${payUrl.indexOf('?') > -1 ? '&' : '?'}buzz=${buzz}`;
              loadingToast.clear();
              window.location.href = url;
            }
          }, 1500);
          return;
        }
        // 总价为0或者折扣抵扣至0(总价=折扣金额)直接去支付结果页面
        if (price === 0 || price === discount) {
          this.$router.push({
            name: 'paymentResult',
            query: {
              mainOrderNo: order?.mainOrderNo,
              source: window.sessionStorage.getItem('_source'),
              // saleChannelId: window.sessionStorage.getItem('_saleChannelId'),
            },
          });
        } else {
          this.$toast.clear(true);
          const loadingToast = this.$toast.loading({
            message: this.$t('GLOBAL_034'),
            duration: 0,
            forbidClick: true,
            loadingType: 'spinner',
            className: isMobile() ? 'confirm-pay-toast' : '',
          });
          setTimeout(() => {
            loadingToast.clear();
            sessionStorage.removeItem('confirmCheck');
            const loginMsg =
              window.localStorage.getItem('loginMsg') &&
              JSON.parse(window.localStorage.getItem('loginMsg'));
            const payUrl = data?.url;
            const companyCode = loginMsg?.companyEncode || '';
            const token = loginMsg?.jwtToken || window.localSource.getItem('SP_TOKEN');
            const buzz = encodeURIComponent(JSON.stringify({ companyCode, token }));
            const url = `${payUrl}${payUrl.indexOf('?') > -1 ? '&' : '?'}buzz=${buzz}`;
            window.location.href = url;
          }, 1500);
        }
        callback && callback(true);
      } else if (code === 'BX-FW-2068') {
        // 【珑珠定制-202311】龙湖积分异常
        callback && callback(false);
        console.log('in error code', code);
        this.$toast.fail({
          message,
          duration: 3000,
        });
      } else if (code === '-1008') {
        // 【珑珠定制-202311】在珑珠check界面，直接跳转订单列表页面
        if (this.$route.name === 'lfPayCheck') {
          this.$router.replace({
            name: 'policy',
          });
        } else if (this.$route.name === 'policy') {
          this.$toast.fail({
            message,
            duration: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          this.$toast.fail({
            message,
            duration: 3000,
          });
        }
      } else {
        callback && callback(true);
        this.$toast.fail({
          message,
          duration: 3000,
        });
      }
    },

    /**
     * 获取页面配置
     * @param {*} pageType 页面配置类型
     * @param {*} needcompanyCode 是否需要companyCode参数，如个人中心不需要
     * @param {*} extra 额外参数
     * @returns
     */
    async getCustomConfig(pageType, extra = {}) {
      const params = {
        pageType,
        companyCode: window.sessionStorage.getItem('_companyCode'),
        ...extra,
      };
      return new Promise(resolve => {
        this.$apis
          .getProductList(params)
          .then(res => {
            const { code, data } = res || {};
            // 获取国籍收集配置
            this.getNationalityCollectionConfig(data?.projNo);
            if (code === '0') {
              // 标题配置
              this.documentTitle = data.pageList?.[0]?.pageName;
              this.SET_CONFIG_DATA(data);
              resolve(data);
            } else if (
              window.sessionStorage.getItem('_access') === 'PUBLIC' &&
              ['BX-FW-2051', '-1019', '35020'].includes(code) &&
              this.$route.name === 'userCenter'
            ) {
              this.documentTitle = userCenterDefaultData?.pageList?.[0]?.pageName;
              this.SET_CONFIG_DATA(userCenterDefaultData);
              resolve(userCenterDefaultData);
            } else {
              resolve(null);
            }
          })
          .catch(() => {
            resolve(null);
          });
      });
    },

    // 当前时间是否小于time
    isDown(val) {
      const time = val && val.replace(/-/g, '/'); //兼容ios 时间处理
      const nowtime = new Date();
      const endtime = new Date(time);
      if (nowtime < endtime) return true;
      return false;
    },
    // 非人生事件的时间判断 0 未开始预热期  1 已开始开窗期 2 已结束
    isAnnualBenefits(obj) {
      const { openPeriodEnd = '', openPeriodStart = '' } = obj || this.openingTime?.[0] || {};
      if (!openPeriodStart || this.isDown(openPeriodStart)) {
        return 0;
      } else if (this.isDown(openPeriodEnd)) {
        return 1;
      } else {
        return 2;
      }
    },
    //查询事件id
    getOpenPeriodId() {
      const type = this.$route?.query?.chooseType;
      const LifeEventOpen = JSON.parse(sessionStorage.getItem('LifeEventOpen'));
      if (type) {
        if (this.openingTime?.length === 1 && type !== 'LIFE_EVENTS') {
          const [openingTime] = this.openingTime;
          //非人生事件
          if (openingTime?.openPeriodType === type) {
            return openingTime?.openPeriodId || '';
          }
        } else {
          // 预热期  返回人生事件id
          if (LifeEventOpen?.openPeriodType === type) {
            return this.reselectVisible ? LifeEventOpen?.openPeriodId || '' : '';
          }
        }
      }
      // 没有事件类型 取开窗期的事件ID
      if (this.isAnnualBenefits() === 1) {
        const [openingTime] = this.openingTime;
        return openingTime?.openPeriodId || '';
      }
      if (LifeEventOpen && LifeEventOpen?.insuredId && this.isLifeEventOpen(LifeEventOpen)) {
        return LifeEventOpen?.openPeriodId;
      }

      return '';
    },
    isLifeEventOpen(LifeEventOpen) {
      const isSelfOpen = this.isOpen({
        openPeriodStart: LifeEventOpen?.openPeriodStart,
        openPeriodEnd: LifeEventOpen?.openPeriodEnd,
      });
      if (isSelfOpen) return true;
      if (+LifeEventOpen?.cycleFlag === 1) {
        return this.isCirculationOpen({
          monthlyDayStart: LifeEventOpen?.monthlyDayStart,
          monthlyDayEnd: LifeEventOpen?.monthlyDayEnd,
        });
      }
    },
    //选择福利跳转
    async choose(isReturn = false, item) {
      const isContinue = await this.checkToken();
      if (!isContinue) {
        this.saveNoLoginEvent(item);
        return;
      }
      const TYPE = this.isAnnualBenefits();
      let url = '';
      if (this.openingTime?.length > 1) {
        return this.$toast(this.openingTime?.[0]?.tipsContent1 || this.$t('GLOBAL_035'));
      }
      const openPeriodType = this.openingTime?.[0]?.openPeriodType || 'EMPTY';
      // 排除掉存入store的是人生事件的情况，且未开启年度选择
      if ([0, 2].includes(TYPE)) {
        const messageMap = {
          ANNUALLY: this.$t('GLOBAL_036'),
          ANNUALLY_MULTI: this.$t('GLOBAL_037'),
          MONTHLY: this.$t('GLOBAL_038'),
          EMPTY: this.$t('GLOBAL_039'),
        };
        const msg = this.openingTime?.[0]?.tipsContent1 || messageMap[openPeriodType];
        if (window.sessionStorage.getItem('_homeErrorRedirect')) {
          window.sessionStorage.removeItem('_homeErrorRedirect');
          this.$router.replace({ name: 'error', query: { msg } });
        } else {
          this.$toast(msg);
        }
        return;
      }
      if (TYPE === 1) {
        // 根据insuredId判断是否在名单内
        if (this.openingTime?.[0]?.insuredId) {
          url = this.getRoutersUrl('/choose', {
            chooseType: openPeriodType,
          });
        } else {
          const msg = this.openingTime?.[0]?.tipsContent1 || this.$t('GLOBAL_040');
          if (window.sessionStorage.getItem('_homeErrorRedirect')) {
            window.sessionStorage.removeItem('_homeErrorRedirect');
            this.$router.replace({ name: 'error', query: { msg } });
          } else {
            this.$toast(msg);
          }
          return;
        }
      }
      if (isReturn) return url;
      this.initInsuranceNotes(url, openPeriodType);
    },
    // 埋点
    buryingBtn(type) {
      const obj = {
        notInOpenWindowPeriodTips: { code: 'B10003', name: '弹福首页' },
        notOnListTips: { code: 'B10004', name: '弹福首页' },
        warmTips: { code: 'B10005', name: '弹福首页' },
      };
      if (type && obj[type]) {
        this.$ET(obj[type].code, obj[type].name);
      }
    },
    //人生事件
    async lifeEvents(val, isReturn = false) {
      const isContinue = await this.checkToken();
      if (!isContinue) {
        this.saveNoLoginEvent(val);
        return;
      }
      const companyCode = getQueryString('companyCode') || sessionStorage.getItem('_companyCode');
      const { data, code } = await this.$apis.getLifeEventOpenPeriodConfig({
        companyCode,
        insuredId: this.insuredId,
      });
      let url = '';
      if (code === '0') {
        // 默认提示
        const message = val?.remindContent || this.$t('GLOBAL_042');
        if (!data) {
          await this.$customDialog({
            title: this.$t('GLOBAL_041'),
            message,
            confirmButtonText: this.$t('GLOBAL_043'),
            themeColor: this.themeColor,
          }).then(() => {
            this.buryingBtn('warmTips');
          });
          return;
        } else {
          data?.insuredId && this.SET_INSURED_ID(data?.insuredId);
          // 不在开窗提示 notInOpenWindowPeriodTips 不在名单提示 notOnListTips   温馨提示 warmTips
          if (data?.enableSwitch) {
            await this.$customDialog({
              title: this.$t('GLOBAL_041'),
              message: data?.tipsContent1 || message,
              confirmButtonText: data?.tipsContent2 || this.$t('GLOBAL_043'),
              themeColor: this.themeColor,
            }).then(() => {
              this.buryingBtn(data?.tipsType);
            });
            // 非 温馨提示 进行阻断
            if (data?.tipsType !== 'warmTips') {
              return;
            }
          }
          if (data?.openPeriodId) {
            sessionStorage.setItem('LifeEventOpen', JSON.stringify(data));
            const openingTime = this.openingTime;
            const isRun =
              openingTime?.length === 1 && openingTime?.[0]?.openPeriodType === 'LIFE_EVENTS';
            // 多开窗无配置 加入开窗时间配置
            if (!openingTime?.length || isRun) {
              this.SET_DATA([data]);
            }
            url = this.getRoutersUrl('/choose', {
              chooseType: data?.openPeriodType || 'LIFE_EVENTS',
            });
            if (isReturn) {
              return url;
            }
            this.initInsuranceNotes(url, data?.openPeriodType || 'LIFE_EVENTS');
          }
        }
      }
    },
    async wefareOverview(isReturn = false) {
      // 如果企业绑定了项目id，跳转新的福利总览页 localStorage.getItem('_projNo')
      if (this.projNo) {
        return this.wefareOverviewWithProjectCode(isReturn);
      }
      const res = await this.$apis.queryAllPlan({
        companyEncode: window.sessionStorage.getItem('_companyCode'),
        projNo: this.projNo || '',
      });
      const { code, data } = res || {};
      let path;
      if (code === '0' && data?.nextPage === 'paid') {
        path = '/detail';
      } else {
        path = data?.nextPage === 'not_selected' ? '/unselected' : '/uncreated';
      }
      const url = this.getRoutersUrl(path);

      if (isReturn) {
        return url;
      }
      window.location.href = transferDomain(url);
      // this.$router.push({ name: NAME });
    },
    //新福利总览跳转
    async wefareOverviewWithProjectCode(isReturn = false) {
      const res = await this.$apis.queryAnnualOptions({
        companyCode: window.sessionStorage.getItem('_companyCode'),
        projectCode: localStorage.getItem('_projNo') || '',
        source,
      });
      const { code, data } = res || {};
      let path;
      if (code === '0' && data?.length > 0) {
        path = '/detailQuicker';
      } else {
        path = '/uncreated';
      }
      const url = this.getRoutersUrl(path);
      if (isReturn) {
        return url;
      }
      window.location.href = transferDomain(url);
      // this.$router.push({ name: NAME });
    },
    // 处理跳转快速咨询
    async handleCreateQuick(url, isReturn = false) {
      console.log('处理跳转快速咨询: ', url);
      // 先判断是否有服务中的单子
      // 查询是否有服务中的服务单
      const params = {
        statuses: '0,1',
        serveTypes: '12',
        serviceMethods: '5',
        patientId: window.sessionStorage.getItem('_personId') || '',
      };
      let hrefUrl = '';
      const res = await this.$apis.checkServing(params);
      if (res?.code === '0') {
        if (res?.data && Array.isArray(res.data) && res.data?.length) {
          // 如果返回的data是数组且有数据,代表有服务中的单子
          const ret = res.data?.[0] || {};
          const data = { ...ret, serviceOrderId: ret.serviceId, doctorType: 1 };
          // 进入聊天室
          const userInfos = JSON.parse(window.localStorage.getItem('userInfo')) || {};
          const ids = {
            orderItemId: userInfos.orderItemId,
            personId: userInfos.personId,
          };
          console.log('data====>>>> ', JSON.stringify(data));
          const chatRoom = {
            chatRoomId: data?.chatRoomId || '',
            serviceOrderId: data?.serviceOrderId || '',
            doctorType: data?.doctorType || '',
            doctorName: data?.doctorName || '',
            doctorId: data?.doctorId || '',
            bizCode: 'QB',
            healthDetails: JSON.stringify(ids),
          };
          const logoInfo = JSON.parse(window.localStorage.getItem('loginMsg'));
          const token = logoInfo?.jwtToken;
          const __userId = logoInfo?.userId;
          const _source = window.sessionStorage.getItem('_source');
          const a = document.createElement('a');
          a.href = url;
          const newUrl = `https://${a.hostname}/service-consult/index?chatRoomId=${chatRoom.chatRoomId}&serviceOrderId=${chatRoom.serviceOrderId}&doctorType=${chatRoom.doctorType}&doctorName=${chatRoom.doctorName}&doctorId=${chatRoom.doctorId}&bizCode=${chatRoom.bizCode}&healthDetails=${chatRoom.healthDetails}&token=${token}&userId=${__userId}&__SOURCE__=${_source}`;
          //
          console.log('newUrl: ', newUrl);
          hrefUrl = newUrl;
        } else {
          const logoInfo = JSON.parse(window.localStorage.getItem('loginMsg'));
          const token = logoInfo?.jwtToken;
          const __userId = logoInfo?.userId;
          const __source = window.sessionStorage.getItem('_source');
          const __personId = sessionStorage.getItem('_personId');
          const a = document.createElement('a');
          a.href = url;
          // &token=${token}&userId=${__userId}&__SOURCE__=${_source}
          const urlParams = getUrlAllParams(url) || {};
          urlParams.token = token;
          urlParams.__SOURCE__ = __source;
          urlParams.userId = __userId;
          urlParams.personId = __personId;
          const queryString = objAddToUrlParams(urlParams);
          const newUrl = `https://${a.hostname}${a.pathname}?${queryString}`;
          console.log('newUrl: ', newUrl);
          hrefUrl = newUrl;
        }
      }
      if (isReturn) {
        return hrefUrl;
      }
      window.location.href = hrefUrl;
    },
    /**
     * @description:   跳转地址格式化
     * @param {*} url 地址
     * @param {*} obj 参数
     * @return {*}
     */
    hrefForm(url, obj) {
      const params = stringifyObject(obj);
      return urlcat(url, params);
    },
    /**
     * @description: 获取域名地址
     * @param {*} path  相对地址
     * @return {*}
     */
    getRoutersUrl(path, obj = {}) {
      const url = window.location.origin + this.$router.options?.base + path;
      return this.hrefForm(url, {
        ...obj,
      });
    },
    /**
     * @description: 序列redirectType 处理
     * @param {*} redirectType
     * @return {*}
     */
    async goToRedirectType(item) {
      const { redirectType, pathName } = item;
      // 常规楼层判断
      switch (redirectType) {
        case 1: // 通用跳转
          if (/turnover/.test(item.url) && /type=ddky/.test(item.url)) {
            this.showDDKY = true;
            this.DDKYUrl = item.url;
          } else if (
            item.url.includes('/order/creatQuickService') ||
            item.url.includes('/medical-service/middleIndex')
          ) {
            // 处理快速咨询入口
            return this.handleCreateQuick(item.url, true);
          }
          return isMobile() ? item.url : item?.pcUrl || item.url;
        case 2: // 商品详情页
          return this.getRoutersUrl('/insuranceDetail', {
            spuCode: item.skuCode,
            projNo: localStorage.getItem('_projNo') || '',
          });
        // case 3: // 体检保保单列表，已下线
        // return `${Config.physicalUrl}/order`;
        case 4: // 健康落地页
          return this.getRoutersUrl('/medicalHome');
        case 5: // 保险落地页
          if (isLiby) this.saveNoLoginEvent({ redirectType: 14 });
          return this.getRoutersUrl('/insurance');
        // case 6: // 体检保落地页，已下线
        //   return `${Config.physicalUrl}/index?token=${jwtToken}&source=${source}&companyCode=${this
        //     .$route.query.companyCode || window.sessionStorage.getItem('_companyCode')}`;
        case 7: // 个人中心
          return this.getRoutersUrl('/userCenter');
        case 8: // 结算确认页
          return this.getRoutersUrl('/settlementConfirm');
        case 9: {
          // 弹福首页   noLoginHomepage 腾讯子公司、商汤首页
          const querys = this.$route.query;
          return this.getRoutersUrl(sourceNoLogin ? '/noLoginHomepage' : '/homepage', {
            companyCode: querys?.companyCode || window.sessionStorage.getItem('_companyCode') || '',
            source: querys?.source || window.sessionStorage.getItem('_source') || '',
            pageType: querys?.pageType || 1,
            silentToChoose:
              isLiby && window.location.href.indexOf('/health-fw-static/insurance') > -1 ? 1 : 0,
          });
        }
        case 10: // 资料下载(福利政策)
          return this.getRoutersUrl('/welfarePolicy');
        case 11: // 积分页
          return this.getRoutersUrl(
            `/${pathName || (isAAutoQuicker ? 'balanceQuicker' : 'balance')}`,
          );
        case 12:
          this.skipAction({ redirectType: 2 }, 'personService');
          break;
        case 13:
          this.skipAction({ redirectType: 'index' }, 'personService');
          break;
        case 14: // 选择福利页
          return this.choose(true, item);
        case 15: // 福利总览
          this.skipAction({ redirectType: 'index' }, 'wefareOverview');
          break;
        case 16: // 权益详情页 benefitDetail
          this.skipAction({ redirectType: 'index' }, 'benefitDetail');
          break;
        case 17: // 医疗订单页 personOrder
          this.skipAction({ redirectType: 3 }, 'personOrder');
          break;
        case 18: // 购药订单页
          this.skipAction({ redirectType: 4 }, 'personOrder');
          break;
        case 19: // 保险订单页
          this.skipAction({ redirectType: 2 }, 'personOrder');
          break;
        case 20: // 体检订单页
          this.skipAction({ redirectType: 5 }, 'personOrder');
          break;
        case 21: // 保险服务页
          this.skipAction({ redirectType: 2 }, 'personService');
          break;
        case 22: // 医疗服务页
          this.skipAction({ redirectType: 3 }, 'personService');
          break;
        case 23: // 家庭成员页
          this.skipAction({ redirectType: '' }, 'familyMember');
          break;
        case 24: // 我的处方页
          this.skipAction({ redirectType: '' }, 'personPrescription');
          break;
        case 25: // 修改手机号
          this.skipAction({ redirectType: '' }, 'updatePhone');
          break;
        case 26: // 关于我们
          this.skipAction({ redirectType: '' }, 'aboutUs');
          break;
        case 27: // 人生事件
          return this.lifeEvents(item, true);
        case 29: // trs
          this.skipAction({ redirectType: '' }, 'trs');
          break;
        case 30: // 福利报销
          this.toReimbursement();
          break;
      }
    },
    // 福利报销跳转
    async toReimbursement() {
      const companyCode =
        this.$route.query.companyCode || window.sessionStorage.getItem('_companyCode');
      const projNo = this.projNo || localStorage.getItem('_projNo');
      if (projNo) {
        const { data, code } = await this.$apis.getApplyForToSubmitted({
          projNo,
        });
        if (code === '0') {
          if (data.bizCode !== '10103') {
            window.location.href = `${window.location.origin}/health/health-reimbursement-static/homepage?companyCode=${companyCode}&projNo=${projNo}`;
            return;
          }
          const response = await this.$apis.getReimbursementDetail({
            comId: companyCode,
            projNo,
          });
          if (response.code === '0') {
            const find = response.data?.pageConfigList?.find(i => i.lang === this.$i18n.locale);
            const {
              alertTitle = '',
              alertContent = '',
            } = find?.pageConfigList[0]?.alertConfigList?.find(i => i.alertType === 'DISABLED');
            this.$customDialog({
              title: alertTitle,
              message: alertContent,
              confirmButtonText: this.$t('GLOBAL_043'),
              themeColor: this.themeColor,
            });
          }
        }
      }
    },
    /**
     * @description: 点击埋点
     * @param {*} itemKey
     * @param {*} if
     * @return {*}
     */
    clickPoint(itemKey) {
      if (itemKey === 'updatePhone') {
        this.$ET('B10001', '个人中心页');
      }
      if (itemKey === 'familyMember') {
        this.$ET('B10004', '个人中心页');
      }
    },
    // 配置页面普通楼层统一处理
    async skipAction(item, itemKey, isNav = false) {
      // 离职员工禁用
      const offJobItems = {
        myWefare: '已离职，不可查看',
        updatePhone: '已离职，不可使用',
      };
      if (
        +window.sessionStorage.getItem('_isOnJob') !== 1 &&
        Object.keys(offJobItems)?.includes(itemKey)
      ) {
        this.$toast(offJobItems?.[itemKey]);
        return;
      }

      // 第三方定制统一处理
      const isContinue = await thirdpartyResolve(item);
      if (!isContinue) return;

      //埋点
      this.clickPoint(itemKey);
      console.log('skipAction', item?.redirectType, itemKey);
      const { redirectType } = item;
      const sourceInfo = JSON.parse(window.localStorage.getItem('SOURCE_INFO'));
      const source = sourceInfo?.source;
      const loginInfo = JSON.parse(window.localStorage.getItem('loginMsg')) || {};
      const { jwtToken, userId, phone, isTest } = loginInfo;
      const openid = window.localStorage.getItem('_openid');
      const list = [
        'defaultIndividual',
        'inenticalIndividual',
        'quickNav',
        'welfare',
        'welfareOverviewBanner',
        'benefitPortal',
        'bottomNavigationBar',
      ];
      let url = ''; //其他项目的用url
      let path = ''; //本项目的用路径
      // 固定楼层特殊判断
      if (itemKey && !list.includes(itemKey)) {
        const urlConfig = {
          personOrder: {
            2: this.getRoutersUrl('/policy'), // 保险订单(对应当前保险落地页展示的保险订单)
            3: ``, // 医疗订单暂空
          },
          personService: {
            2: `${window.location.origin}${process.env.VUE_APP_CLAIM_BASE_URL}/insuranceOrder/index`, // 保险服务(对应当前个人中心-我的订单-保险订单)
            3: `${process.env.VUE_APP_INTERNET_HOSPITAL_BASE_URL}/service-consult/service-record`, // 医疗服务(对应当前个人中心-我的订单-医疗订单)
            index: this.getRoutersUrl('/medicalHome'), // 医疗服务首页 特殊处理
          },
        };
        // 根据公众号不同，区分普药和特药
        const pathConfig = {
          familyMember: { path: `/familyMember` }, // 家庭成员
          personPrescription: {
            url: `${process.env.VUE_APP_INTERNET_HOSPITAL_BASE_URL}/prescription/prescription-list`,
          }, // 我的处方
          personVisit: { url: `` }, // 我的随访
          personCard: {
            url: `${process.env.VUE_APP_PHARMACY_CARD_URL}`,
          }, // 我的会员卡
          aboutUs: { path: '/aboutUs' }, // 关于我们
          myWefare: {
            path: isAAutoQuicker ? '/balanceQuicker' : '/balance',
          },
          trs: {
            url: `${
              window.location.origin
            }/health/health-reimbursement-static/trs/homepage?lang=${window.localStorage.getItem(
              '_language',
            )}`,
          },
          medicalService: {
            url: `${process.env.VUE_APP_INTERNET_HOSPITAL_BASE_URL}/service-consult/service-record`, // 个人中心-医疗订单
          },
          insuranceClaim: {
            url: `${window.location.origin}${process.env.VUE_APP_CLAIM_BASE_URL}/insuranceOrder/index`, // 个人中心-保险理赔
          },
        };

        if (urlConfig[itemKey] && urlConfig[itemKey][redirectType]) {
          url = urlConfig[itemKey][redirectType];
        } else if (pathConfig[itemKey]) {
          url = pathConfig[itemKey].url;
          path = pathConfig[itemKey].path;
        }
        if (url) {
          const src = itemKey === 'personOrder' && redirectType == 2 ? 'siPaiJian1Bao' : '';
          let obj = {
            token: jwtToken,
            userId,
            openid,
            isTest,
            whereFromWxSource: src,
            __SOURCE__: source,
            defaultSource: source,
          };
          // 去掉phone，解决bigo安全问题
          if (source != 1502610105) {
            obj.phone = phone;
          }
          const params = stringifyObject(obj);
          url = urlcat(url, params);
        } else if (path) {
          url = this.getRoutersUrl(path);
        } else if (itemKey === 'benefitDetail') {
          //权益详情
          url = `${window.location.origin}${process.env.VUE_APP_HEALTH_MANAGEMENT_BASE_URL}/medical-service/equity-detail?token=${jwtToken}&userId=${userId}&type=1`;
        } else if (itemKey === 'personOrder' && redirectType === 4) {
          // 购药订单
          this.showDDKY = true;
        } else if (itemKey === 'updatePhone') {
          this.show = true;
        } else if (itemKey == 'wefareOverview') {
          url = await this.wefareOverview(true);
        } else {
          url = '';
          this.$toast(this.$t('GLOBAL_044'));
        }
      } else {
        url = await this.goToRedirectType(item);
      }
      if (url && typeof url === 'string') {
        const reg = /^(http|https):\/\/.*$/i;
        if (!reg.test(url)) {
          // 非常规链接，是电话拨号或发送邮件
          window.location.href = url;
          return;
        }

        url = transferDomain(url);

        const urlObject = new URL(url);
        if (sourceNoLogin) {
          // 第三方链接直接跳转免登录
          if (!securityDomainList.includes(urlObject?.hostname)) {
            window.location.href = url;
            return;
          }
          // 腾讯子公司、商汤、立白定制跳转前置处理
          /**
           * 不在白名单内页面的登录前置处理
           */
          const paths = routes.filter(i => loginRouterWhiteList.includes(i.name));
          const pathWhiteList = paths?.map(i => `/health-fw-static${i.path}`);
          if (pathWhiteList.every(i => url.indexOf(i) < 0)) {
            const isContinue = await this.checkToken(url);
            if (!isContinue) return;
          }

          /**
           * 完善信息前置处理
           * 1.在个人中心页
           * 2.跳转链接不是弹福相关域名
           * 3.用户未认证
           * 4.跳转页面不是个人中心-关于我们
           * 5.不是点击的导航栏
           */
          const { name } = this.$route;
          if (
            (name === 'userCenter' ||
              window.location.href.indexOf('/health-fw-static/userCenter') > -1) &&
            securityDomainList.includes(urlObject?.hostname) &&
            store.state.$_account.bizStatus < 2 &&
            url.indexOf('/health-fw-static/aboutUs') < 0 &&
            !isNav
          ) {
            window.location.href = `${
              window.location.origin
            }/spcare-products/health-fw-static/supply?redirectUrl=${encodeURIComponent(url)}`;
            return;
          }
        }
        if (
          jwtToken &&
          securityDomainList.includes(urlObject?.hostname) &&
          urlObject?.hostname !== window.location.hostname
        ) {
          url = removeUrlParam(url, 'token');
          url += `${url?.indexOf('?') > -1 ? '&' : '?'}token=${jwtToken}`;
        }
        // 如果是跳转平安理赔，需要已认证
        if (url.indexOf('/turnover') > -1 && store.state.$_account.bizStatus < 2) {
          window.location.href = `${
            window.location.origin
          }/spcare-products/health-fw-static/supply?redirectUrl=${encodeURIComponent(url)}`;
          return;
        }

        // 跳转互联网医院
        if (url.indexOf(process.env.VUE_APP_INTERNET_HOSPITAL_BASE_URL) > -1) {
          goHospitalPage({ path: url });
          return;
        }

        // 投保须知前置校验
        if (url?.indexOf('/spcare-products/health-fw-static/choose') > -1) {
          const openPeriodType = getQueryString('chooseType', true, url);
          if (openPeriodType) {
            this.initInsuranceNotes(url, openPeriodType);
            return;
          }
        }
        window.location.href = url;
      }
    },
    /**
     * 处理订单异常
     */
    onResolveError(insurants) {
      for (let [i, v] of insurants.entries()) {
        switch (v.returnCode) {
          case 'O_10000':
            break;
          case 'O_10001':
            this.$toast(`参保人-${i + 1}证件号码不正确`); //O_10001 "O_10001", "证件号码不正确"
            return;
          case 'O_10002':
            this.$toast(`参保人-${i + 1}生日不正确`); //O_10002  "O_10002", "生日不正确"
            return;
          case 'O_10003':
            this.$toast(`参保人-${i + 1}与投保人关系不正确`); //O_10003 "O_10003", "与投保人关系不正确"
            return;
          case 'O_10004':
            this.$toast(`参保人-${i + 1}投保关键信息重复`); //O_10004  "O_10004", "投保关键信息重复"
            return;
          case 'O_10005':
            // 已下单未支付
            this.$customDialog({
              title: this.$t('GLOBAL_045'),
              message: this.$t('GLOBAL_046'),
              confirmButtonText: this.$t('GLOBAL_047'),
              themeColor: this.themeColor,
            })
              .then(() => {
                this.$router.push({
                  name: 'policy',
                  query: {
                    source: window.sessionStorage.getItem('_source'),
                  },
                });
              })
              .catch(() => {});
            return;
          case 'O_10006':
            // 已下单未承保
            this.$customDialog({
              title: this.$t('GLOBAL_048'),
              message: this.$t('GLOBAL_049'),
              confirmButtonText: this.$t('GLOBAL_050'),
              themeColor: this.themeColor,
            })
              .then(() => {
                this.$router.push({
                  name: 'policy',
                  query: {
                    source: window.sessionStorage.getItem('_source'),
                  },
                });
              })
              .catch(() => {});
            return;
          case 'O_10007':
            this.$toast(`参保人-${i + 1}证件号有特殊字符`);
            return;
          case 'O_10011':
            this.$toast(`参保人-${i + 1}护照长度错误`);
            return;
          case 'O_10012':
            this.$toast(`参保人-${i + 1}港澳台回乡证号码错误`);
            return;
          case 'O_10013':
            this.$toast(`参保人-${i + 1}投保年龄不够`);
            return;
          case 'O_10014':
            this.$toast(`参保人-${i + 1}姓名格式错误`);
            return;
          case 'O_10015':
            this.$toast(`参保人-${i + 1}未满18岁`);
            return;
          case 'O_10016':
            this.$toast(`参保人-${i + 1}年龄不在保险产品被保年龄范围`);
            return;
          case 'O_10018':
            this.$toast(`参保人-${i + 1}性别与您相同，请确认被保险人身份`);
            return;
          case 'O_10019':
            this.$toast(`参保人-${i + 1}不支持的证件类型`);
            return;
          case 'O_10020':
            this.$toast(`参保人-${i + 1}年龄应大于员工年龄`);
            return;
          case 'O_10021':
            this.$toast(`参保人-${i + 1}年龄应小于员工年龄`);
            return;
          case 'O_10022':
            this.$toast(`仅可添加1个配偶`);
            return;
          case 'O_10053':
            this.reteToast(insurants);
            return;
          default:
            v?.reason && this.$toast(v.reason);
            break;
        }
      }
    },
    reteToast(insurants) {
      const names = insurants?.reduce((strs, item) => {
        if (item?.returnCode === 'O_10053') {
          return (strs += strs ? `、${item?.name}` : item?.name);
        }
        return strs;
      }, '');
      if (names) {
        this.$customDialog({
          title: this.$t('GLOBAL_051'),
          message: this.$t('GLOBAL_052', { name: names }),
          confirmButtonText: this.$t('GLOBAL_053'),
          themeColor: this.themeColor,
        }).then(() => {});
      }
    },

    /**
     * 检测登录状态，并依据入参条件是否执行跳转
     * @param {string} redirectUrl 回跳地址
     * @param {boolean} needRedirect 是否需要跳转
     * @param {boolean} needAuth 是否进一步验证认证
     * @returns
     */
    async checkToken(redirectUrl = window.location.href, needRedirect = true, needAuth = false) {
      let url = redirectUrl;
      // 避免重复存储sso信息
      url = removeUrlParam(url, 'ssoUniqueCode');
      const loginMsg =
        window.localStorage.getItem('loginMsg') &&
        JSON.parse(window.localStorage.getItem('loginMsg'));
      if (loginMsg) {
        const checkLoginResponse = await this.$apis.checkLoginStatus({
          token: loginMsg?.jwtToken,
          source: window.sessionStorage.getItem('_source'),
        });
        const isLogin = checkLoginResponse?.code == 0 && checkLoginResponse?.data;
        if (!isLogin) {
          window.localStorage.removeItem('SP_TOKEN');
          window.localStorage.removeItem('loginMsg');
          if (needRedirect) {
            window.location.href = `${
              window.location.origin
            }/spcare-products/health-fw-static/middleware?redirectUrl=${encodeURIComponent(url)}`;
          }
          return false;
        } else {
          if (needAuth && store.state.$_account.bizStatus < 2) {
            window.location.href = `${
              window.location.origin
            }/spcare-products/health-fw-static/supply?redirectUrl=${encodeURIComponent(url)}`;
            return;
          }
          return true;
        }
      } else {
        if (needRedirect) {
          window.location.href = `${
            window.location.origin
          }/spcare-products/health-fw-static/middleware?redirectUrl=${encodeURIComponent(url)}`;
        }
        return false;
      }
    },

    // 针对在无登录态首页发生的需要登录态的事件做临时存储
    saveNoLoginEvent(item) {
      // 如果是选择福利/人生事件，或是立白企业且去投保，可记录事件
      const redirectTypes = [14, 27];
      const canSet = redirectTypes.includes(item?.redirectType);
      if (canSet) {
        window.sessionStorage.setItem('_noLoginHomepageEventItem', JSON.stringify(item));
      }
    },
  },
};
